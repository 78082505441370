import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { observer, Provider } from 'mobx-react';
import { ApolloProvider } from '@apollo/react-hooks';
import GraphClient from './client.js';
import authStore from './store/Auth';
import AdminPanel from './components/AdminPanel';
import ClientPresView from './components/ClientPresView/index';
import AdminPresView from './components/AdminPresView';
import GatedAdminPage from './components/GatedAdminPage';
import HomePage from './components/HomePage';
import NotFound from './components/404NotFound';
import theme from 'src/styles/muiTheme';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PollPage from './components/PollPage';
import TriviaPage from './components/TriviaPage';
import EditPollPage from './components/EditPollPage';
import EditTriviaPage from './components/EditTriviaPage';
import DebugPage from './components/DebugPage/index.js';
import PollSequencePage from './components/PollSequencePage';
import EditPollSequencePage from './components/EditPollSequencePage';
import ManageUsersView from './components/ManageUsersView/index.js';

class App extends Component {
  state = {
    currentPres: null,
    isNotificationOpen: false,
    isLoadingPerms: true,
  };

  componentDidMount() {
    this.init();
  }

  async init() {
    try {
      await authStore.checkLogin();
      await authStore.getUserPermissions();
      this.setState({ isLoadingPerms: false });
    } catch (e) {
      console.log('init error', e);
    }
  }

  closeNotification = () => {
    this.setState({ isNotificationOpen: false, currentPres: null });
  };

  openNotification = pres => {
    if (pres) {
      this.setState({ currentPres: pres, isNotificationOpen: true });
    }
  };

  render() {
    const { currentPres, isNotificationOpen } = this.state;
    // console.log(authStore.authUser, 'authstore app');
    const authUser = authStore.authUser;
    const permissions = authStore.permissions;
    return (
      <main>
        <Dialog
          open={isNotificationOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {currentPres && (
            <>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {`This presentation was locked by ${
                    currentPres.owner.displayName
                  }
               at ${moment(currentPres.lockedAt).format('LLL')}).
              If you need it unlocked, please contact ${
                currentPres.owner.email
              }`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeNotification} color="primary">
                  Dismiss
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
        <ApolloProvider client={GraphClient}>
          <Provider authStore={authStore}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={props => <HomePage {...props} />}
                  />
                  <Route
                    path="/admin/edit-poll"
                    exact
                    render={props => (
                      <GatedAdminPage
                        {...props}
                        authStore={authStore}
                        hasAuth={authUser && !this.state.isLoadingPerms && authStore.permissions.canUpdate}
                        authChild={EditPollPage}
                      />
                    )}
                  />
                  <Route
                    path="/admin/edit-trivia"
                    exact
                    render={props => (
                      <GatedAdminPage
                        {...props}
                        authStore={authStore}
                        hasAuth={authUser && !this.state.isLoadingPerms && authStore.permissions.canUpdate}
                        authChild={EditTriviaPage}
                      />
                    )}
                  />
                  <Route
                    path="/admin"
                    exact
                    render={props => (
                      <GatedAdminPage
                        {...props}
                        authStore={authStore}
                        hasAuth={authUser && !this.state.isLoadingPerms && Object.values(authStore.permissions.groups).some(Boolean)}
                        authChild={AdminPanel}
                      />
                    )}
                  />
                  <Route
                    path="/admin/users"
                    exact
                    render={props => (
                      <GatedAdminPage
                        {...props}
                        authStore={authStore}
                        hasAuth={permissions.canManageUsers}
                        authChild={ManageUsersView}
                      />
                    )}
                  />
                  <Route
                    path="/p/:slug"
                    exact
                    render={props => <ClientPresView {...props} />}
                  />
                  <Route
                    path="/a/:slug"
                    exact
                    render={props => (
                      <GatedAdminPage
                        {...props}
                        headerFade={true}
                        authStore={authStore}
                        hasAuth={authUser}
                        openNotification={this.openNotification}
                        authChild={AdminPresView}
                      />
                    )}
                  />
                  <Route
                    path="/test"
                    exact
                    render={props => <DebugPage {...props} />}
                  />
                  <Route path="/poll/:pollId" component={PollPage} />
                  <Route path="/trivia/:triviaId" component={TriviaPage} />
                  <Route
                    path="/admin/edit-poll-sequence/:pollSequenceId"
                    exact
                    render={props => (
                      <GatedAdminPage
                        {...props}
                        authStore={authStore}
                        authChild={EditPollSequencePage}
                      />
                    )}
                  />
                  <Route
                    path="/poll-sequence/:pollSequenceId"
                    component={PollSequencePage}
                  />

                  <Route render={() => <NotFound />} />
                </Switch>
              </ThemeProvider>
            </BrowserRouter>
          </Provider>
        </ApolloProvider>
      </main>
    );
  }
}

export default observer(App);
