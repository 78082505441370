import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import firebase from 'firebase';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { ColorPicker } from 'material-ui-color';
import Alert from '../Alert';
import Loader from '../Loader';
import Preview from '../Preview';
import styles from './style.module.scss';
import { db } from '../../lib/loadFirebase';

const NUM_OPTIONS = 6;

function EditTriviaPage() {
  const [selected, setSelected] = useState('');
  const [isNew, setIsNew] = useState(false);
  const [trivia, setTrivia] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      options: [],
      question: '',
      slug: '',
      theme: {
        background: '#ffffff',
        buttonBg: '#C9234B',
        buttonBorderRadius: '100px',
        buttonText: '#ffffff',
        correctBg: '#6aa84f',
        incorrectBg: '#d9d9d9',
        selectedBg: '#0B3056',
        text: '#0B3056',
      },
    },
    onSubmit: async values => {
      setLoading(true);
      const query = db.collection('trivia').doc(values.slug);

      if (isNew) {
        await query.set({
          ...values,
          options: values.options.filter(option => !!option.value),
        });

        setTrivia({ ...trivia, [values.slug]: values });
      } else {
        await db.collection('trivia').doc(selected).set(values);

        // Delete empty options
        for (const option of values.options) {
          if (!option.value) {
            await db
              .collection('trivia')
              .doc(selected)
              .update({
                options: firebase.firestore.FieldValue.arrayRemove(option),
              });
          }
        }

        setTrivia({ ...trivia, [selected]: values });
      }

      setLoading(false);

      setSelected(values.slug);
    },
  });

  async function init() {
    const snapshots = await db.collection('trivia').get();

    if (snapshots.docs.length) {
      const docs = snapshots.docs.map(doc => doc.data());

      setTrivia(keyBy(docs, 'slug'));
    }
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (selected) {
      const selectedTrivia = trivia[selected];

      if (selectedTrivia) {
        setIsNew(false);
        formik.setValues({ ...selectedTrivia });
      } else {
        setIsNew(true);
        formik.handleReset();
      }
    }
  }, [selected]);

  function toggleAlert() {
    setShowAlert(!showAlert);
  }

  function handleOptionChange(value, i) {
    formik.setFieldValue(`options[${i}].label`, value);
    // Set value to a, b, c, d, etc...
    formik.setFieldValue(
      `options[${i}].value`,
      !!value ? String.fromCharCode(97 + i) : ''
    );

    // If option is just created, set the isCorrect value to false
    if (get(formik, ['values', 'options', i, 'isCorrect']) === undefined) {
      formik.setFieldValue(`options[${i}].isCorrect`, false);
    }

    if (!value) {
      formik.setFieldValue(`options[${i}].isCorrect`, false);
    }
  }

  function handleIsCorrectChange({ target: { checked } }, i) {
    formik.setFieldValue(`options[${i}].isCorrect`, checked);
  }

  async function handleDelete() {
    setLoading(true);

    try {
      await db.collection('trivia').doc(selected).delete();

      const { [selected]: deleted, ...remaining } = trivia;
      setTrivia(remaining);

      setSelected('');
    } catch (error) {
      // TODO: Add error handling
      console.log(error);
    } finally {
      toggleAlert();
      setLoading(false);
    }
  }

  function renderColorPicker(title, prop) {
    return (
      <>
        <InputLabel shrink>{title}</InputLabel>
        <ColorPicker
          value={get(formik, `values.theme.${prop}`)}
          onChange={color =>
            formik.setFieldValue(`theme.${prop}`, `#${color.hex}`)
          }
          inputFormats={['hex', 'rgb']}
          disableTextfield
          deferred
        />
      </>
    );
  }

  function renderContent() {
    if (loading) {
      return (
        <div className={styles.loader}>
          <Loader />
        </div>
      );
    }

    if (!!selected) {
      return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={2}>
            <Grid
              item
              container
              direction="row"
              sm={12}
              md={8}
              spacing={3}
              justify="space-around"
            >
              <Grid
                item
                container
                direction="column"
                sm={12}
                md={6}
                spacing={1}
              >
                <Grid item>
                  <h2>Questions and options</h2>
                </Grid>
                <Grid item>
                  <TextField
                    id="slug"
                    label="Slug"
                    value={formik.values.slug}
                    onChange={formik.handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Question"
                    id="question"
                    value={formik.values.question}
                    onChange={formik.handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                {Array.from(Array(NUM_OPTIONS)).map((_, i) => (
                  <Grid item container key={`option[${i}]`}>
                    <Grid item xs={9} lg={10}>
                      <TextField
                        id={`option[${i}]`}
                        label={`Option ${i + 1}`}
                        value={get(
                          formik,
                          ['values', 'options', i, 'label'],
                          ''
                        )}
                        onChange={e => handleOptionChange(e.target.value, i)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={get(
                              formik,
                              ['values', 'options', i, 'isCorrect'],
                              false
                            )}
                            onChange={e => handleIsCorrectChange(e, i)}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Is correct"
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                container
                direction="column"
                sm={12}
                md={6}
                spacing={1}
              >
                <Grid item>
                  <h2>Theme</h2>
                </Grid>
                {[
                  { title: 'Page Background', prop: 'background' },
                  { title: 'Question Text', prop: 'text' },
                  { title: 'Button Background', prop: 'buttonBg' },
                  { title: 'Button Text', prop: 'buttonText' },
                  { title: 'Correct Answer Background', prop: 'correctBg' },
                  { title: 'Incorrect Answer Background', prop: 'incorrectBg' },
                  { title: 'Selected Answer Background', prop: 'selectedBg' },
                ].map(({ title, prop }) => (
                  <Grid item key={prop}>
                    {renderColorPicker(title, prop)}
                  </Grid>
                ))}
                <Grid item>
                  <TextField
                    label="Button Border Radius"
                    value={formik.values.theme.buttonBorderRadius}
                    onChange={e =>
                      formik.setFieldValue(
                        'theme.buttonBorderRadius',
                        e.target.value
                      )
                    }
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="flex-end">
                <Grid item>
                  <Button variant="contained" color="primary" type="submit">
                    {isNew ? 'Create' : 'Save'}
                  </Button>
                </Grid>
                {!isNew && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={toggleAlert}
                      >
                        Delete
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        href={`${window.location.origin}/trivia/${selected}`}
                        target="_blank"
                      >
                        Show Trivia
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={12} md={4}>
              <Grid item>
                <Preview type="trivia" values={formik.values} />
              </Grid>
            </Grid>
          </Grid>
        </form>
      );
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.select}>
        <FormControl fullWidth>
          <InputLabel id="poll-slug-label">Trivia</InputLabel>
          <Select
            labelId="poll-slug-label"
            id="poll-slug-select"
            value={selected}
            onChange={e => setSelected(e.target.value)}
          >
            <MenuItem value="new">Create a new trivia</MenuItem>
            {Object.keys(trivia).map(value => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText id="my-helper-text">
            Create a new trivia or edit an existing one
          </FormHelperText>
        </FormControl>
      </div>
      {renderContent()}
      <Alert
        open={showAlert}
        title={'Are you sure you want to delete this trivia?'}
        handleConfirm={handleDelete}
        handleClose={toggleAlert}
        confirmText="Delete"
      >
        <DialogContentText>
          This item will be deleted immediately. You can't undo this action.
        </DialogContentText>
      </Alert>
    </div>
  );
}

export default EditTriviaPage;
