import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../Loader';
import styles from './style.module.scss';
import { confTypes } from 'src/constants';

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPending: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.presentations.length !== this.props.presentations.length) {
      this.setState({ isPending: false });
      this.props.closeConfirmationModal()
    }

    if (this.props.error && this.state.isPending) {
      this.setState({ isPending: false });
    }

  }

  handleConfirm = () => {
    this.props.handleConfirm(this.props.currentPres);

    if (this.props.confirmationType === confTypes.REMOVE) {
      this.setState({ isPending: true });
    }
  };

  render() {
    const dialogTitle = this.props.error
      ? ''
      : this.state.isPending
        ? this.props.content.pendingMsg
        : this.props.content.heading;
    const dialogCopy = (this.props.error ||
      this.props.currentPres) && this.props.content.message;

    const dialogContent = (
      <>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogCopy}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.closeConfirmationModal()} color="primary">
            {this.props.error ? 'Close' : 'Cancel'}
          </Button>
          {!this.props.error && this.props.handleConfirm && (
            <Button onClick={this.handleConfirm} color="primary" autoFocus>
              Confirm
            </Button>
          )}
        </DialogActions>
      </>
    );

    const loader = (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );

    return (
      <Dialog
        open={this.props.isConfirmationOpen}
        onClose={() => this.props.closeConfirmationModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={this.props.classes}
      >
        {
          <>
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            {this.state.isPending ? loader : dialogContent}
          </>
        }
      </Dialog>
    );
  }
}

export default withStyles({
  paper: {
    minWidth: '300px',
    maxWidth: '500px'
  },
})(ConfirmationModal);
