import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { db } from '../../lib/loadFirebase';
import Question from '../../components/Question';
import Results from '../../components/Results';
import '../../styles/_app.scss';

const questionData = {
  question: '',
  subtitle: '',
  options: [],
};

class PollPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasVoted: false,
      skipVote: false,
      showControls: false,
      data: null,
      isFinished: false,
      questionData: questionData,
      counts: {},
    };
    const urlParams = new URLSearchParams(window.location.search);

    this.boundOnVoted = this.onVoted.bind(this);
    this.boundOnReset = this.onReset.bind(this);
    this.boundOnEndVoting = this.onEndVoting.bind(this);

    this.mode = urlParams.get('mode');
    this.showTitle = JSON.parse(urlParams.get('show_title') || true);
    const admin = urlParams.get('admin');

    if (admin === 'true') {
      this.state.showControls = true;
    }
    const res = urlParams.get('results');
    if (res === 'true') {
      this.state.skipVote = true;
    }
  }
  init = async () => {
    this.listeners = [];

    const pollId =
      this.props.pollId || (this.props.match && this.props.match.params.pollId);
    let questionQuery = db.collection(`polls`).where('slug', '==', pollId);

    const snapshots = await questionQuery.get();

    if (snapshots.docs.length) {
      const data = snapshots.docs[0].data();
      this.setState({ hasVoted: false });
      this.setQuestionData(data);
      let shardCollections = data.options.map(item => {
        return item.value;
      }); // ['a','b','c','d',...]
      for (let i = 0; i < data.options.length; i++) {
        const query = db.collection(
          `polls/${pollId}/${shardCollections[i]}Shards`
        );
        const unsubscribe = query.onSnapshot(snapshot => {
          const total = snapshot.docs.reduce((result, item) => {
            return result + item.data().count;
          }, 0);
          const counts = { ...this.state.counts };
          counts[shardCollections[i]] = total;
          this.setState({ counts: counts }, () => {
            this.setQuestionData(data);
          });
        });
        this.listeners.push(unsubscribe);
      }
    } else {
      this.setState({ error: 'Poll not found! :(' });
      console.log('No such document!');
    }
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    console.log(this.props.pollId, prevProps.pollId, 'didupdate');
    if (this.props.pollId && this.props.pollId !== prevProps.pollId) {
      this.listeners.forEach(listener => listener());
      this.init();
    }
  }

  setQuestionData(data) {
    let sortedData = data.options.map(option => {
      return {
        label: option.label,
        value: this.state.counts[option.value] || 0, //actual count
        isCorrect: option.isCorrect,
      };
    });
    if (data.theme.resultSortType && data.theme.resultSortType === 'desc') {
      sortedData = sortedData.sort((a, b) => b.value - a.value);
    }

    this.setState({
      data: sortedData,
      isFinished: data.isFinished,
      questionData: data,
      theme: data.theme,
    });
  }

  onReset() {
    //TODO
  }

  onEndVoting() {
    const { props } = this;
    const pollId = props.pollId || (props.match && props.match.params.pollId);
    db.doc(`polls/${pollId}`).update({
      isFinished: true,
    });
  }

  componentWillUnmount() {
    this.listeners.forEach(listener => listener());
  }

  onVoted() {
    this.setState({ hasVoted: true });
    window.scrollTo(0, 0);
  }

  render() {
    const {
      questionData,
      theme,
      data,
      skipVote,
      hasVoted,
      isFinished,
      error,
    } = this.state;
    const pollId =
      this.props.pollId || (this.props.match && this.props.match.params.pollId);
    const { question, options, subtitle } = questionData;
    if (error) {
      return <div>{error}</div>;
    }
    if (!data) {
      return <div></div>;
    }
    const { mode, controlled } = this.props;
    const showResults =
      ((hasVoted || skipVote) && !controlled) ||
      mode === 'results' ||
      (mode === 'reveal' && controlled) ||
      (mode === 'auto' && controlled && hasVoted);
    const showQuestion =
      ((mode === 'question' && controlled) ||
        (mode === 'auto' && controlled && !hasVoted) ||
        (!hasVoted && !skipVote && !controlled)) &&
      options.length;
    return (
      <div className="App">
        <ReactCSSTransitionGroup
          transitionName="fade"
          transitionAppear={true}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {showResults && data && (
            <Results
              data={data}
              showAnswer={this.mode === 'reveal' || mode === 'reveal'}
              theme={theme}
              question={question}
            />
          )}
          {showQuestion && (
            <Question
              options={options}
              question={this.showTitle && question}
              questionType={'polls'}
              subtitle={subtitle}
              onVoted={this.boundOnVoted}
              source={''}
              theme={theme}
              pollId={pollId}
            />
          )}
          {this.state.showControls &&
            !this.state.hasVoted && [
              <div onClick={this.boundOnReset} className="btn reset">
                Reset
              </div>,
              <div onClick={this.boundOnEndVoting} className="btn end">
                End Voting
              </div>,
            ]}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

export default PollPage;
