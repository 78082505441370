import React from 'react';
import style from './styles.module.css';
import { observer } from 'mobx-react';

class LoginButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { authStore } = this.props;
    if (!authStore) {
      return <div></div>;
    }
    return (
      <div className={style.login}>
        {!authStore.authUser && (
          <div
            onClick={() => {
              authStore.showGoogleLogin();
            }}
          >
            Login
          </div>
        )}
        {authStore.authUser && (
          <div
            onClick={() => {
              this.setState({ showProfileModal: true });
            }}
            className={style.profileContainer}
          >
            <img
              className={style.profileImg}
              src={authStore.authUser.photoURL}
              alt="profile"
            />
          </div>
        )}
        {this.state.showProfileModal && authStore.authUser && (
          <div className={style.profileModal}>
            <div
              className={style.closeOverlay}
              onClick={() => {
                this.setState({ showProfileModal: false });
              }}
            />
            <div className={style.profilePopup}>
              <div>{authStore.authUser.displayName}</div>
              <div>{authStore.authUser.email}</div>
              <div
                className={style.logout}
                onClick={() => {
                  authStore.logout();
                }}
              >
                Logout
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default observer(LoginButton);
