import React, { useState } from 'react';
import moment from 'moment';
import clx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ConfirmationModal from '../ConfirmationModal';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { Flipped, spring } from 'react-flip-toolkit';
import { confTypes } from 'src/constants';

const muiStyles = {
  checkbox: {
    color: '#fff !important',
  },
  formControl: {
    opacity: 0.85,
    marginLeft: '-7px',
    marginRight: 0,
  },
  disabled: {
    color: 'grey !important',
  },
  exportInfo: {
    position: 'absolute',
    bottom: '30px'
  }
};

const onExit = (el, index, removeElement) => {
  spring({
    config: { overshootClamping: true },
    onUpdate: val => {
      el.style.opacity = 0;
    },
    delay: index * 10,
    onComplete: removeElement,
  });

  return () => {
    el.style.opacity = '';
    removeElement();
  };
};

function PresentationCard({
  pres,
  presentations,
  isArchiveTabShowing,
  handleClientLinkCopyClick,
  togglePresentationLock,
  handleDeleteClick,
  copiedLinkSlug,
  handleArchiveClick,
  handleEditClick,
  handleHistoryClick,
  permissions,
  classes,
  userEmail,
  error,
  authStore,
}) {
  const [confirmationType, setConfirmationType] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const isLocked = !!pres.isLocked;

  const lockCheckboxClicked = () => {
    if (isLocked && userEmail !== pres.owner.email) {
      openConfirmationModal(confTypes.UNLOCK)
    } else {
      togglePresentationLock(pres);
    }
  };

  const openConfirmationModal = (confirmationType) => {
    setConfirmationType(confirmationType)
    setIsConfirmationOpen(true)
  }

  const closeConfirmationModal = () => {
    authStore.setError(null);
    setIsConfirmationOpen(false)
  };

  const handleUnlockConfirm = (pres) => {
    togglePresentationLock(pres);
    setIsConfirmationOpen(false)
  }


  const renderConfirmationModal = (lockedMsg) => {
    if (confirmationType) {
      let confProps;

      switch (confirmationType) {
        case confTypes.REMOVE:
          confProps = {
            handleConfirm: handleDeleteClick,
            content: {
              heading: 'Remove Presentation?',
              pendingMsg: 'Removing...',
              message: `Are you sure you want to remove the
          exported presentation "${pres.name}" from the admin panel?`
            }
          }
          break;
        case confTypes.UNLOCK:
          confProps = {
            handleConfirm: handleUnlockConfirm,
            content: {
              heading: 'Unlock Presentation?',
              pendingMsg: 'Unlocking...',
              message: `${lockedMsg} A
                presentation might currently be in progress. Are you sure you want
                to unlock it?`
            }
          }
          break;
        default:
      }

      return (
        <ConfirmationModal
          presentations={presentations}
          currentPres={pres}
          error={error}
          isConfirmationOpen={isConfirmationOpen}
          openConfirmationModal={openConfirmationModal}
          closeConfirmationModal={closeConfirmationModal}
          confirmationType={confirmationType}
          {...confProps}
        />
      )
    };
  }

  const allButtonsDisabled = isLocked && userEmail !== pres.owner.email;
  const lockedMsg = pres.owner && `This presentation was locked by ${pres.owner.displayName}
      (${pres.owner.email}) on ${moment(pres.lockedAt).format('M/D/YY')}
      at ${moment(pres.lockedAt).format('h:mm a')}.`;
  const isPublished = pres.isPublished === undefined ? true : pres.isPublished;

  const { exportedBy, exportedAt } = pres;

  return (
    <Flipped key={pres.id} flipId={pres.id} onExit={onExit} stagger={'forward'}>
      <div className={styles.listItem}>
        <div className={styles.thumbnailContainer}>
          <img src={`${pres.thumbnailUrl}`} alt="thumbnail" />
          <div className={styles.thumbnailOverlay}>
            {!permissions.canUpdate && isLocked ? (
              <Typography align="center">
                {lockedMsg}
              </Typography>
            ) : (
                <div className={styles.actionContainer}>
                  <Link to={`/a/${pres.slug}`}
                    className={clx(`link`, styles.action, allButtonsDisabled && styles.disabled)}>
                    <span className={styles.p}>
                      <i className="material-icons">open_in_new</i>
                      <span className={styles.text}>Present{!isPublished && '*'}</span>
                    </span>
                  </Link>
                  <a href={`https://docs.google.com/presentation/d/${pres.id}/edit`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clx(`link`, styles.action, allButtonsDisabled && styles.disabled)}>
                    <span className={styles.p}>
                      <i className="material-icons">exit_to_app</i>
                      <span className={styles.text}>View Source</span>
                    </span>
                  </a>
                  <FormControlLabel
                    className={`${classes.formControl}`}
                    label="Lock Presentation"
                    disabled={!permissions.canUpdate}
                    control={
                      <Checkbox
                        checked={isLocked}
                        className={clx(
                          classes.checkbox,
                          !permissions.canUpdate && classes.disabled
                        )}
                        onChange={lockCheckboxClicked}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                  />
                  <div
                    className={clx(styles.action, allButtonsDisabled && styles.disabled)}
                    onClick={() => handleClientLinkCopyClick(pres.slug)}
                  >
                    {copiedLinkSlug && copiedLinkSlug === pres.slug ? (
                      <span className={styles.text}>Link Copied!</span>
                    ) : (
                        <>
                          <i className="material-icons">file_copy</i>{' '}
                          <span className={styles.text}>Copy Client Link</span>
                        </>
                      )}
                  </div>
                  <div
                    className={clx(
                      styles.action,
                      (!permissions.canUpdate || allButtonsDisabled) && styles.disabled
                    )}
                    onClick={() => {
                      permissions.canUpdate &&
                        handleEditClick({
                          ...pres,
                        });
                    }}
                  >
                    <i className="material-icons">edit</i>
                    <span className={styles.text}>Settings</span>
                  </div>
                  <div
                    className={clx(styles.action)}
                    onClick={() => handleHistoryClick({ ...pres })}
                  >
                    <i className="material-icons">history</i>
                    <span className={styles.text}>History</span>
                  </div>
                  <div
                    className={clx(
                      styles.action,
                      (!permissions.canUpdate || allButtonsDisabled) && styles.disabled
                    )}
                    onClick={() =>
                      permissions.canUpdate &&
                      handleArchiveClick(pres.id, isArchiveTabShowing)
                    }
                  >
                    <i className="material-icons">
                      {isArchiveTabShowing ? 'unarchive' : 'archive'}
                    </i>
                    <span className={styles.text}>
                      {isArchiveTabShowing ? 'Unarchive' : 'Archive'}
                    </span>
                  </div>
                  <div
                    className={clx(
                      styles.action,
                      (!permissions.canDelete || allButtonsDisabled) && styles.disabled
                    )}
                    onClick={() => {
                      permissions.canDelete && openConfirmationModal(confTypes.REMOVE);
                    }}
                  >
                    <i className="material-icons">delete</i>
                    <span className={styles.text}>Remove</span>
                  </div>
                </div>
              )}
            {exportedBy && exportedAt && (
              <Typography className={classes.exportInfo} variant="caption">
                {`Last exported: ${moment(exportedAt.toDate()).calendar()} by ${exportedBy.name}`}
              </Typography>
            )}

            {!isPublished && (<p className={styles.unpublishedMessage}>
              *Presentation is currently unpublished
            </p>)}
          </div>
        </div>
        <div className={styles.presTitle}>{pres.name}</div>
        {renderConfirmationModal(lockedMsg)}
      </div>
    </Flipped >
  );
}

export default withStyles(muiStyles)(PresentationCard);
