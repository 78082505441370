import React, { Component } from 'react';
import parse, { domToReact } from 'html-react-parser';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { debounced } from './util/index';
import './htm-parser.scss';
import { Box, Typography } from '@material-ui/core';
const DEFAULT_IFRAME_OVERLAY_HEX = '#00ffff';
class HtmParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      videos: [],
      html: null,
    };
  }

  componentDidMount() {
    if (this.props.url) {
      this.fetchExternalHTML();
    } else if (this.props.presentation) {
      this.setPresentationData(
        `<html><body>${this.props.presentation}</body></html>`
      );
    }
    //add resize listener for overlays
    this.boundUpdateOverlays = this.setOverlays.bind(this);
    this.debouncedUpdateOverlays = debounced(500, this.setOverlays.bind(this));
    this.resizeListener = window.addEventListener(
      'resize',
      this.debouncedUpdateOverlays
    );
    this.resizeListener2 = window.addEventListener(
      'resize',
      this.boundUpdateOverlays
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedUpdateOverlays);
    window.removeEventListener('resize', this.boundUpdateOverlays);
  }

  fetchExternalHTML() {
    fetch(this.props.url)
      .then(res => {
        return res.text();
      })
      .then(data => {
        this.setPresentationData(`<html><body>${data}</body></html>`);
      });
  }
  componentDidUpdate(prevProps) {
    if (this.props.slideIdx !== prevProps.slideIdx && this.state.html) {
      const slides = this.state.slidesArr;
      let dom = domToReact(
        [slides[this.props.slideIdx]],
        this.getOptions(null, null, true)
      );
      let dom2 =
        this.props.slideIdx + 1 < slides.length
          ? domToReact([slides[this.props.slideIdx + 1]], this.getOptions())
          : null;
      this.setState({ currentMarkup: dom, nextMarkup: dom2 }, () => {
        setTimeout(() => {
          this.setOverlays();
          this.setActiveSlideId();
        }, 50);
      });
    }
    if (
      this.props.showNextPreview !== prevProps.showNextPreview ||
      this.props.showSpeakerNotes !== prevProps.showSpeakerNotes
    ) {
      setTimeout(() => {
        this.setOverlays();
      }, 50);
    }
  }

  setActiveSlideId() {
    const slideId = document.querySelector('.main-container svg g').id;
    if (this.props.onReadCurrentSlideId) {
      this.props.onReadCurrentSlideId(slideId);
    }
  }

  setPresentationData(html) {
    html = html.replace(/crossorigin="use-credentials"/g, 'crossorigin=""');
    let dom = parse(html, this.getOptions(html, null, true));
    const slides = this.state.slidesArr;
    let dom2 =
      this.props.slideIdx + 1 < slides.length
        ? domToReact([slides[this.props.slideIdx + 1]], this.getOptions())
        : null;
    this.setState({ currentMarkup: [dom], nextMarkup: [dom2] }, () => {
      setTimeout(() => {
        this.setOverlays();
        this.setActiveSlideId();
      }, 50);
    });
  }

  getYouTubeGetID(url) {
    var ID = '';
    url = url
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      // eslint-disable-next-line
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  }

  renderUi() {
    return (
      <div className="ui-container" key="ui">
        <div
          className="btn"
          onClick={() => this.goPrevious()}
        >{`Previous`}</div>
        <div className="slide-label">Slide: {this.state.page + 1}</div>
        <div className="btn" onClick={() => this.goNext()}>{`Next`}</div>
        <div className="btn" onClick={() => this.resetSlides()}>{`Reset`}</div>
      </div>
    );
  }
  hasModuleData(attribs) {
    return (
      attribs &&
      attribs['aria-label'] &&
      attribs['aria-label'].indexOf('Module Data') !== -1
    );
  }

  hasIframeOverlay(attribs) {
    return (
      this.hasModuleData(attribs) &&
      attribs['aria-label'].indexOf('iframeOverlay') !== -1
    );
  }

  //V1
  renderVimeo(attribs) {
    const vidIdIdx = attribs['aria-label'].indexOf('vimeoId:');
    let id = attribs['aria-label'].substr(vidIdIdx + 9, 9);
    const overlay = (
      <iframe
        key={`video ${id}`}
        title={`video ${id}`}
        className="video-bg"
        src={`https://player.vimeo.com/video/${id}?autoplay=1&amp;muted=1&amp;loop=1&amp;controls=1&amp;byline=0&amp;portrait=0&amp;background=1&amp;allow=autoplay`}
        allowFullScreen=""
        frameBorder="0"
        style={{ background: this.props.theme.background }}
      ></iframe>
    );
    return overlay;
  }

  renderIframeOverlay(url, elm) {
    const res = elm.getBoundingClientRect();
    const iframeOverlay = (
      <iframe
        style={{
          position: 'absolute',
          top: this.props.isPortraitLayout ? '0px' : `${Math.floor(res.y)}px`,
          left: this.props.isPortraitLayout ? '0px' : `${Math.floor(res.x)}px`,
          zIndex: '1',
        }}
        key={`iframe-overlay`}
        title="iframe overlay"
        width={this.props.isPortraitLayout ? '100%' : res.width + 1}
        height={this.props.isPortraitLayout ? '100%' : res.height + 1}
        src={url}
        frameBorder="0"
        allow="autoplay; fullscreen"
        autoPlay
        allowFullScreen
      ></iframe>
    );
    return iframeOverlay;
  }

  setOverlays() {
    console.log('setting overlays');
    const overlayData = [{ key: 'overlays', slideIdx: this.props.slideIdx }];
    if (this.props.slideIdx + 1 < this.state.slidesArr.length) {
      overlayData.push({
        key: 'nextOverlays',
        slideIdx: this.props.slideIdx + 1,
      });
    }
    let overlays = [[], []]; //current overlays and nextOverlays
    let makeTransparent = false;
    for (var i = 0; i < overlayData.length; i++) {
      const options = {
        // TODO refactor
        // eslint-disable-next-line
        replace: ({ name, attribs, children }) => {
          if (this.hasModuleData(attribs) && !this.hasIframeOverlay(attribs)) {
            const vidIdIdx = attribs['aria-label'].indexOf('vimeoId:');
            if (vidIdIdx !== -1) {
              const overlay = this.renderVimeo(attribs);
              overlays[i].push(overlay);
            }
          }
          if (this.hasModuleData(attribs)) {
            const iframeUrlIdx = attribs['aria-label'].indexOf('iframeUrl:');
            const isBehindSlideIdx = attribs['aria-label'].indexOf(
              'isBehindSlide: true'
            );
            if (isBehindSlideIdx !== -1) {
              makeTransparent = true;
            }
            if (iframeUrlIdx !== -1) {
              const url = attribs['aria-label'].substr(iframeUrlIdx + 11);

              const overlay = (
                <iframe
                  key={`iframe ${url}`}
                  title={`video ${url}`}
                  className="video-bg"
                  src={url}
                  allowFullScreen=""
                ></iframe>
              );

              overlays[i].push(overlay);
            }
          }
          // if (
          //   name === 'image' &&
          //   attribs['crossorigin']
          // ) {
          //   console.log('crossorigin', attribs['crossorigin']);
          //   const overlay = (
          //     <image href={attribs['xlink:href]']} help="me" key={`image ${attribs['xlink:href']}`} />
          //   );
          //   overlays[i].push(overlay);
          // }
          if (
            name === 'a' &&
            attribs[`xlink:href`] &&
            attribs[`xlink:href`].indexOf('/www.youtube.com/watch') !== -1 &&
            i === 0
          ) {
            const youtubeId = this.getYouTubeGetID(
              decodeURIComponent(attribs[`xlink:href`])
            );
            const slideId = document.querySelector('.main-container svg g').id;
            //TODO removing slides api refs since not consistent with autoplay
            // const activeSlide = this.props.slidesApiSlides.find(slide => {
            //   return slide.objectId === slideId;
            // });
            // const activeObject = activeSlide.pageElements.find(elm => {
            //   return elm.video && elm.video.id === youtubeId;
            // });
            // const {
            //   start,
            //   end,
            //   autoPlay,
            //   mute,
            // } = activeObject.video.videoProperties;

            // let url = `https://www.youtube.com/embed/${youtubeId}?autoplay=${
            //   autoPlay ? 1 : 0
            // }&amp;mute=${
            //   mute ? 1 : 0
            // }&amp;modestbranding=1&amp;controls=1&amp;rel=0${
            //   start ? '&amp;start=' + start / 1000 : ''
            // }${end ? '&amp;end=' + end / 1000 : ''}`;
            let url = `https://www.youtube.com/embed/${youtubeId}`;
            const elm = [...document.querySelectorAll('a')].find(elm => {
              return (
                elm.href &&
                elm.href.baseVal &&
                elm.href.baseVal === attribs[`xlink:href`]
              );
            });
            if (elm) {
              const videoOverlay = this.renderIframeOverlay(url, elm);
              overlays[i].push(videoOverlay);
            }
          }
          //inner iframe
          if (this.hasIframeOverlay(attribs)) {
            const vidIdIdx = attribs['aria-label'].indexOf('vimeoId:');
            if (vidIdIdx !== -1) {
              const overlay = this.renderVimeo(attribs);
              overlays[i].push(overlay);
            }
            const iframeOverlaySegment = attribs['aria-label']
              .split(',')
              .find(segment => {
                return segment.indexOf('iframeOverlay:') !== -1;
              });
            const iframeUrlIdx = iframeOverlaySegment.indexOf('iframeOverlay:');
            const iframeOverlayHexIdx = attribs['aria-label'].indexOf(
              'iframeOverlayHex:'
            );
            const iframeHex =
              iframeOverlayHexIdx === -1
                ? DEFAULT_IFRAME_OVERLAY_HEX
                : attribs['aria-label'].substr(iframeOverlayHexIdx + 18, 7);
            if (iframeUrlIdx !== -1) {
              const elm = [...document.querySelectorAll('path')].find(elm => {
                return (
                  elm.attributes.fill && elm.attributes.fill.value === iframeHex
                );
              });

              if (elm) {
                const url = iframeOverlaySegment.substr(iframeUrlIdx + 15);
                const iframeOverlay = this.renderIframeOverlay(url, elm);
                overlays[i].push(iframeOverlay);
              }
            }
          }
          // modules V2 checks image source for an iframeUrl instead of aria text of text box TODO remove, see v3
          if (
            name === 'image' &&
            attribs[`xlink:href`] &&
            attribs[`xlink:href`].indexOf('iframeUrl') !== -1
          ) {
            const params = new URL(attribs['xlink:href']).searchParams
              .toString()
              .split('&')
              .reduce((previous, current) => {
                const [key, value] = current.split('=');
                previous[key] = value;
                return previous;
              }, {});
            let url = decodeURIComponent(params.iframeUrl);

            const imageElm = [...document.querySelectorAll('image')].find(
              elm => {
                return (
                  elm.href &&
                  elm.href.baseVal &&
                  elm.href.baseVal === attribs[`xlink:href`]
                );
              }
            );
            if (imageElm) {
              const elm = document.getElementById(
                imageElm.attributes['clip-path'].value
                  .replace('url(#', '')
                  .replace(')', '')
              );

              //hide placeholder image
              if (i === 0) {
                imageElm.style.opacity = 0;
              }

              const iframeOverlay = this.renderIframeOverlay(url, elm);
              overlays[i].push(iframeOverlay);
            }
          }

          //v3 iframe url method -> presentably adds a link element to every special embed image, this looks for the special link(ending in presentablyEmbed), replaces it with iframe, and hides the preceeding element(the paired placeholder image)
          if (
            name === 'a' &&
            attribs[`xlink:href`].indexOf('presentablyEmbed') !== -1
          ) {
            const params = new URL(attribs['xlink:href']).searchParams
              .toString()
              .split('&')
              .reduce((previous, current) => {
                const [key, value] = current.split('=');
                previous[key] = value;
                return previous;
              }, {});
            let url = decodeURIComponent(params.q).replace(
              'presentablyEmbed',
              ''
            );

            const linkElm = [...document.querySelectorAll('a')].find(elm => {
              return (
                elm.href &&
                elm.href.baseVal &&
                elm.href.baseVal === attribs[`xlink:href`]
              );
            });
            if (linkElm) {
              const imageElm = linkElm.previousSibling;

              //hide placeholder image
              if (i === 0) {
                imageElm.style.opacity = 0;
              }

              const iframeOverlay = this.renderIframeOverlay(url, linkElm);
              overlays[i].push(iframeOverlay);
            }
          }

          const driveVideoStr = '?driveVideo=true';
          if (
            name === 'a' &&
            attribs[`xlink:href`] &&
            attribs[`xlink:href`].indexOf(driveVideoStr) !== -1
          ) {
            var mute = true;
            var autoPlay = true;
            var start = 0;
            var end;
            //TODO: removing this block for now videoProperties is no longer accurate
            // if (i === 0) {
            //   const slideId = document.querySelector('.main-container svg g')
            //     .id;
            //   const activeSlide = this.props.slidesApiSlides.find(slide => {
            //     return slide.objectId === slideId;
            //   });
            //   const activeObject = activeSlide.pageElements.find(elm => {
            //     return (
            //       elm.video &&
            //       attribs[`xlink:href`].indexOf(elm.title.replace('.', '')) !==
            //         -1
            //     );
            //   });
            //   var {
            //     start,
            //     end,
            //     autoPlay,
            //     mute,
            //   } = activeObject.video.videoProperties;
            // }

            const elm = [...document.querySelectorAll('a')].find(elm => {
              return (
                elm.href &&
                elm.href.baseVal &&
                elm.href.baseVal === attribs[`xlink:href`]
              );
            });
            if (elm) {
              const res = elm.getBoundingClientRect();
              const sourcesWithAudio = [
                '_EOTW_LightRobotVoice_Louderyouare_V101mp4',
                '1hTiQyNpUWBcXBgRNnr12ZDxQX1Ffsq5h.mp4',
                '_OC_7_DEMO004mp4',
                'Live_barcelona_v6',
                'ARE_APP_MOD_SIZZLE',
                '1cv0MBxRcNdqQ4mgpAz1hLRzzLLf6gByK',
                '1S9KksvNHRYOtto40CzpXOCEZjU5WH5DX',
                '1e3bv68OsjoflLdeSDkhASvk_VJBxdkwu',
                '1jigaV99OQteSBTLyEHIk89odWzGmU77S',
                '_with_audio',
              ];
              let hasAudio = false;
              for (let index = 0; index < sourcesWithAudio.length; index++) {
                const src = sourcesWithAudio[index];
                if (elm.href.baseVal.indexOf(src) >= 0) {
                  hasAudio = true;
                }
              }
              mute = !hasAudio;
              const videoOverlay = (
                <video
                  // poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                  poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAQAAACRI2S5AAAAEklEQVR42mNk+M+AFzCOKgADALyGCQGyq8YeAAAAAElFTkSuQmCC"
                  autoPlay={autoPlay ? true : false}
                  style={{
                    position: 'absolute',
                    top: `${Math.floor(res.y)}px`,
                    left: `${Math.floor(res.x)}px`,
                  }}
                  muted={mute ? true : false}
                  controls={mute ? false : true}
                  loop={mute ? true : false}
                  playsInline
                  width={res.width + 1}
                  height={res.height + 1}
                  key={`video-${elm.href.baseVal}}`}
                >
                  <source
                    src={
                      elm.href.baseVal.substr(
                        0,
                        elm.href.baseVal.length - driveVideoStr.length
                      ) +
                      `#t=${start ? start / 1000 : 0}${
                        end ? ',' + end / 1000 : ''
                      }`
                    }
                    type="video/mp4"
                  />
                </video>
              );
              overlays[i].push(videoOverlay);
            }
          }
        },
      };
      domToReact([this.state.slidesArr[overlayData[i].slideIdx]], options);
    }

    this.setState({
      overlays: overlays[0],
      nextOverlays: overlays[1],
      isTransparent: makeTransparent,
    });
  }

  getOptions(data, slideHasIframe, isActiveLayer = false) {
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === 'html') {
          return (
            <div
              key="outer-container"
              className="main-container"
              style={{
                background:
                  this.props.theme && this.props.theme.background
                    ? this.props.theme.background
                    : '#000000',
              }}
            >
              {domToReact(children, options)}
            </div>
          );
        }
        if (name === 'head') {
          return <div></div>;
        }
        if (name === 'body') {
          if (data) {
            if (this.props.onReadTotalSlides) {
              this.props.onReadTotalSlides(children.length);
            }
            this.setState({
              totalPages: children.length,
              html: data,
              slidesArr: children,
            });
          }
          return (
            <div
              className={`slides-container ${
                this.props.isPortraitLayout ? 'portrait' : ''
              }`}
            >
              {domToReact([children[this.props.slideIdx]], options)}
            </div>
          );
        }
        if (name === 'fegaussianblur') {
          // Fix for filter
          return (
            <feGaussianBlur {...attribs}>
              {domToReact(children, options)}
            </feGaussianBlur>
          );
        }
      },
    };
    return options;
  }

  render() {
    const {
      showNextPreview,
      showSpeakerNotes,
      isPortraitLayout,
      speakerNotes,
      theme,
      slideIdx,
      showControls,
    } = this.props;
    const {
      isTransparent,
      currentMarkup,
      nextMarkup,
      overlays,
      totalPages,
    } = this.state;
    if (!this.state.html) {
      return null;
    }
    return (
      <Box display="flex" justifyContent="center">
        <ReactCSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={600}
          transitionLeaveTimeout={600}
          style={{
            width: showNextPreview || showSpeakerNotes ? '70%' : '100%',
            position: 'relative',
          }}
        >
          <Box
            key={`slide-${slideIdx}`}
            className={`main-container ${isTransparent ? 'is-behind' : ''}`}
            style={{ background: theme.background }}
            display="flex"
          >
            <Box
              className={`slides-container ${
                isPortraitLayout ? 'portrait' : ''
              }`}
              style={{
                margin: showNextPreview || showSpeakerNotes ? 0 : 'auto',
              }}
            >
              {currentMarkup}
            </Box>
            {overlays}
          </Box>
        </ReactCSSTransitionGroup>
        <Box
          display={showNextPreview || showSpeakerNotes ? 'block' : 'none'}
          width="30%"
          style={{ background: '#fafafa' }}
          py={8}
          px={5}
        >
          <Box className={`up-next ${showNextPreview ? 'visible' : 'hidden'}`}>
            <Box>
              <Typography variant="h5" style={{ marginBottom: '16px' }}>
                {slideIdx + 1 < totalPages ? 'Up Next' : 'Last Slide'}
              </Typography>
            </Box>
            {nextMarkup}
          </Box>
          {showSpeakerNotes && (
            <Box mt={8}>
              <Typography variant="h5" style={{ marginBottom: '8px' }}>
                Speaker Notes
              </Typography>
              <Typography>{speakerNotes}</Typography>
            </Box>
          )}
        </Box>
        {showControls && this.renderUi()}
      </Box>
    );
  }
}

export default HtmParser;
