import React from 'react';
import style from './style.module.scss';
import { Typography } from '@material-ui/core';

class Splash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pw: '',
      isLoading: false,
      mounted: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.setState({ mounted: true });
    if (!this.props.showLogin) {
      this.handleSubmit()
    }
  }

  handleSubmit() {
    this.props.onSubmit(this.state.pw);
  }

  render() {
    return (
      <div>
        <div
          className={`${style.gate} ${this.state.mounted ? style.mounted : ''}`}
        >
          <div>{/* <img className={style.logo} src={LFLLogo} alt="" /> */}</div>
          <div className={style.secondary}>
            {this.props.showLogin && (
              <>
                <h2>Presentation Code:</h2>
                <input
                  type="text"
                  value={this.state.pw}
                  onChange={e => {
                    this.setState({ pw: e.target.value });
                  }}
                />
                {this.props.error && (
                  <Typography variant="body1" color="error" gutterBottom>
                    Error: {this.props.error}
                  </Typography>
                )}
              </>
            )}

            {this.props.showLogin && (
              <div className={style.submit} onClick={this.handleSubmit}>
                Join Presentation
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Splash;
