import React, { Component } from 'react';
import clx from 'classnames';
import styles from './styles.module.scss';
import { db } from 'src/lib/loadFirebase.js';
import Checkbox from '../Checkbox';
import { collections } from 'src/constants';
import { debounced } from 'src/util';
import { InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
class SettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emojisEnabled: 'none',
      file: '',
      group: '',
      pw: '',
      isActive: true,
      isPublished: true,
      isPrivate: true,
      title: '',
      slug: '',
      background: '#000000',
      thumbnail: null,
      isEditing: false,
      fileChanged: false,
      slugError: '',
    };
    this.boundUpdateData = this.updateData.bind(this);
    this.debouncedCheckForExistingSlug = debounced(
      500,
      this.checkForExistingSlug.bind(this)
    );
  }

  checkForExistingSlug(val) {
    db.collection(collections.PRESENTATIONS)
      .where('slug', '==', val)
      .get()
      .then(snapshot => {
        if (
          snapshot.docs.length > 1 ||
          (snapshot.docs.length === 1 &&
            snapshot.docs[0].id !== this.props.presentation.id)
        ) {
          this.setState({
            slugError: 'This slug already exists. Please use a unique slug.',
          });
        } else {
          this.setState({
            slugError: null,
          });
        }
      });
  }

  componentDidMount() {
    const { presentation } = this.props;
    if (presentation) {
      const {
        pw,
        name,
        isActive,
        isPrivate,
        isPublished,
        slug,
        background = '#000000',
        emojisEnabled = 'none',
        group = 'ROW',
      } = presentation;
      this.setState({
        group,
        emojisEnabled,
        pw: pw,
        title: name,
        slug: slug,
        background,
        isEditing: true,
        isActive,
        isPrivate,
        isPublished: isPublished === undefined ? true : isPublished,
      });
    }
  }

  updateData = async () => {
    const { id } = this.props.presentation;
    const newSlug = this.state.slug.split(' ').join('-');
    db.collection(collections.PRESENTATIONS)
      .doc(id)
      .set(
        {
          name: this.state.title,
          pw: this.state.pw,
          slug: newSlug,
          background: this.state.background,
          isActive: this.state.isActive,
          isPrivate: this.state.isPrivate,
          isPublished: this.state.isPublished,
          emojisEnabled: this.state.emojisEnabled,
          group: this.state.group,
        },
        { merge: true }
      )
      .then(d => {
        if (!this.state.fileChanged) {
          this.props.onSettingsUpdate();
        }
      });

    db.collection(collections.STATES).doc(id).set(
      {
        slug: newSlug,
        background: this.state.background,
        isActive: this.state.isActive,
        isPrivate: this.state.isPrivate,
        isPublished: this.state.isPublished,
        emojisEnabled: this.state.emojisEnabled,
        group: this.state.group,
      },
      { merge: true }
    );
  };

  render() {
    const { slug } = this.state;
    const groups = this.props.authStore.permissions.groups;
    console.log(this.props.authStore.permissions, this.state.group, 'perms');
    return (
      <div className={styles.settingsContainer}>
        <div className={styles.fieldsContainer}>
          <label className={styles.textField}>
            <span>Url Slug:</span>
            <input
              autoComplete="off"
              id="new-pres-title"
              type="text"
              value={this.state.slug}
              onChange={e => {
                this.debouncedCheckForExistingSlug(e.target.value);
                this.setState({ slug: e.target.value });
              }}
              onKeyPress={e => {
                switch (e.keyCode) {
                  case 8: // Backspace
                  case 9: // Tab
                  case 13: // Enter
                  case 37: // Left
                  case 38: // Up
                  case 39: // Right
                  case 40: // Down
                    break;
                  default:
                    var regex = new RegExp('^[a-zA-Z0-9-]+');
                    var key = e.key;
                    if (!regex.test(key)) {
                      e.preventDefault();
                      return false;
                    }
                    break;
                }
              }}
            />
            {this.state.slugError && (
              <Typography variant="body1" color="error" gutterBottom>
                Error: {this.state.slugError}
              </Typography>
            )}
            <div className={styles.helpTxt}>
              Client Url: {window.location.origin}/p/
              {slug.length ? slug.split(' ').join('-') : `<your-title-here>`}
            </div>
            <div className={styles.helpTxt}>
              Presenter Url: {window.location.origin}/a/
              {slug.length ? slug.split(' ').join('-') : `<your-title-here>`}
            </div>
          </label>
        </div>
        <Checkbox
          label={`Published`}
          isChecked={this.state.isPublished}
          onChange={e => {
            this.setState({ isPublished: e });
          }}
        />
        <div className={styles.helpTxt}>
          {this.state.isPublished
            ? 'Presentation is currently viewable to the client.'
            : 'Presentation is currently unpublished and not viewable to the client.'}
        </div>
        <Checkbox
          label={`Controlled Mode`}
          isChecked={this.state.isActive}
          onChange={e => {
            this.setState({ isActive: e });
          }}
        />
        <div className={styles.helpTxt}>
          {this.state.isActive
            ? 'Client and Presenter view are currently synced. Presenter controls what slide the client sees.'
            : 'Client and Presenter view are currently decoupled. Client is able to navigate the client view freely.'}
        </div>
        <Checkbox
          label={`Private (Password Protected)`}
          isChecked={this.state.isPrivate}
          onChange={e => {
            this.setState({ isPrivate: e });
          }}
        />
        <label
          className={clx(
            styles.textField,
            this.state.isPrivate ? '' : styles.disabled
          )}
        >
          <span>Code:</span>
          <input
            id="new-pres-pw"
            autoComplete="off"
            type="text"
            value={this.state.pw}
            onChange={e => {
              this.setState({ pw: e.target.value });
            }}
          />
          <div className={styles.helpTxt}>
            Presentation will require this code to view.
          </div>
        </label>
        <label className={`${styles.textField} ${styles.colorInput}`}>
          <span>Background:</span>
          <input
            autoComplete="off"
            id="background-color-set"
            type="text"
            value={this.state.background}
            onChange={e => {
              this.setState({ background: e.target.value });
            }}
          />
          <div
            className={styles.colorSwatch}
            style={{ background: this.state.background }}
          ></div>
        </label>
        <label style={{ margin: '10px 0' }} className={`${styles.textField}`}>
          <span>Enable Emojis:</span>
          <Select
            style={{ marginLeft: '10px' }}
            labelId="emoji-label"
            id="emoji-select"
            value={this.state.emojisEnabled}
            onChange={e => {
              this.setState({ emojisEnabled: e.target.value });
            }}
          >
            <MenuItem key="none" value="none">
              None
            </MenuItem>
            {/* <MenuItem key="presentably"archived value="presentably">
              Within Presentably Presentation
            </MenuItem> */}
            <MenuItem key="gaga" value="gaga">
              Within Gaga Stage
            </MenuItem>
          </Select>
        </label>
        <label style={{ margin: '10px 0' }} className={`${styles.textField}`}>
          <span>Group Access:</span>
          <Select
            style={{ marginLeft: '10px' }}
            labelId="group-label"
            id="group-access-select"
            value={this.state.group}
            onChange={e => {
              this.setState({ group: e.target.value });
            }}
          >
            {groups
              ? Object.keys(groups)
                  .filter(key => groups[key] === true)
                  .map(key => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))
              : null}
          </Select>
        </label>
        <div
          className={clx(
            styles.btn,
            styles.submit,
            this.state.slugError || this.state.slug.length === 0
              ? styles.disabled
              : ''
          )}
          onClick={this.boundUpdateData}
        >
          {this.state.isEditing ? 'Update' : 'Submit'}
        </div>
      </div>
    );
  }
}

export default inject('authStore')(observer(SettingsModal));
