import React, { Component } from 'react';
import '../presentation-view.scss';
import './styles.scss';
import axios from 'axios';
import { db } from '../../lib/loadFirebase';
import HtmParser from '../../HtmParser';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { endpoints } from '../../constants';
import Splash from '../Splash';
import Loader from '../Loader';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Box } from '@material-ui/core';
import Emoji from '../Emojis';

class ClientPresView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      presentation: '',
      presentationUrl: '',
      background: '#ffffff00',
      activeSlide: 0,
      checkedPrivacy: false,
      stateDocId: null,
      totalPages: 0,
      isControlled: true,
      showSplash: true,
      codeSubmitError: null,
      isLoading: false,
      portraitPresentationSlug: '',
    };
    const urlParams = new URLSearchParams(window.location.search);
    const mSlug = urlParams.get('m');
    if (mSlug && mSlug.length) {
      this.state.portraitPresentationSlug = mSlug;
    }
    this.checkAuth = this.checkAuth.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSlideChange = this.handleSlideChange.bind(this);
    document.title = 'Presentably: Presentation';
  }

  checkAuth = async pw => {
    this.setState({ isLoading: true, codeSubmitError: null });
    const { slug } = this.props.match.params;

    try {
      const { data } = await axios.post(endpoints.CHECK_AUTH, {
        slug:
          this.state.portraitPresentationSlug || this.props.match.params.slug,
        pw: pw,
      });

      // analytics.logEvent('client_join_success', { presentation: slug });
      window.scrollTo(0, 0);
      let newState = {
        isLoading: false,
        codeSubmitError: null,
      };

      if (data.url) {
        newState.presentationUrl = data.url;
      } else if (data.contents) {
        newState.presentation = data.contents;
      }
      this.setState(newState);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error === 'Not a match'
      ) {
        // analytics.logEvent('client_join_fail', {
        //   presentation: slug,
        //   reason: 'incorrect password',
        // });
        this.setState({ isLoading: false, codeSubmitError: 'Code incorrect' });
      } else {
        this.setState({
          isLoading: false,
          codeSubmitError: 'Please try again',
        });
      }
    }
  };

  handleKeyPress(evt) {
    if (!this.state.isControlled) {
      const { key } = evt;
      if (key === 'ArrowRight' || key === 'ArrowDown') {
        this.goNext();
      } else if (key === 'ArrowLeft' || key === 'ArrowUp') {
        this.goPrevious();
      }
    }
  }

  renderOptions() {
    let options = [];
    for (var i = 0; i < this.state.totalPages; i++) {
      options.push(<MenuItem value={i}>Slide {i + 1}</MenuItem>);
    }
    return options;
  }
  goNext() {
    const { totalPages, activeSlide } = this.state;
    if (totalPages > 0 && activeSlide < totalPages - 1) {
      const newSlideIndex = activeSlide + 1;
      this.setState({ activeSlide: newSlideIndex });
    }
  }

  goPrevious() {
    const { activeSlide, totalPages } = this.state;
    if (totalPages > 0 && activeSlide >= 1 && this.state.stateDocId) {
      const newSlideIndex = activeSlide - 1;
      this.setState({ activeSlide: newSlideIndex });
    }
  }

  handleSlideChange(evt) {
    this.setState({ activeSlide: evt.target.value });
  }

  renderUi() {
    const optionItems = this.renderOptions();
    return (
      <div className="ui-container">
        <div className="btn" onClick={() => this.goPrevious()}>
          <KeyboardArrowLeftIcon />
        </div>
        <FormControl className={'select'}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.activeSlide}
            onChange={this.handleSlideChange}
            style={{ paddingRight: '5px' }}
            IconComponent={() => (
              <KeyboardArrowDownIcon
                fontSize="small"
                style={{ color: 'white' }}
              />
            )}
          >
            {optionItems}
          </Select>
        </FormControl>
        <div className="btn" onClick={() => this.goNext()}>
          <KeyboardArrowRightIcon />
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.unsubscribeFromActiveSlide();
    window.removeEventListener('keyup', this.handleKeyPress);
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeSlide, isControlled, totalPages } = this.state;
    const { slug } = this.props.match.params;
    if (prevState.activeSlide !== activeSlide) {
      // analytics.logEvent('slide_change', {
      //   presentation: slug,
      //   slide: activeSlide,
      //   controlled: isControlled,
      // });
    }
    if (
      prevState.activeSlide !== activeSlide &&
      activeSlide === totalPages - 1
    ) {
      // analytics.logEvent('completed', {
      //   presentation: slug,
      //   controlled: isControlled,
      // });
    }
  }

  componentDidMount = async () => {
    const { slug } = this.props.match.params;
    // analytics.setCurrentScreen(window.location.pathname);
    window.addEventListener('keyup', this.handleKeyPress);
    // get presentation based on slug
    this.unsubscribeFromActiveSlide = db
      .collection('states')
      .limit(1)
      .where('slug', '==', slug)
      .onSnapshot(snapshot => {
        // if snapshot is empty, the param slug doesn't match any presentations
        if (snapshot.empty) {
          this.redirectToNotFound();
        }

        snapshot.forEach(doc => {
          let data = doc.data();
          if (data.activeSlide >= 0) {
            if (data.isPublished === false) {
              this.redirectToNotFound();
            }
            this.setState({
              isControlled: data.isActive,
              activeSlide: data.isActive
                ? data.activeSlide
                : this.state.activeSlide,
              stateDocId: doc.id,
            });
          }
        });
      });
    const privacySlug = this.state.portraitPresentationSlug || slug;

    db.collection('states')
      .where('slug', '==', privacySlug)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let data = doc.data();
          this.setState({
            showLogin: data.isPrivate,
            checkedPrivacy: true,
            background: data.background,
            emojisEnabled: data.emojisEnabled,
          });
        });
      });
  };

  redirectToNotFound = () => {
    this.props.history.push('/not-found');
  };

  render() {
    const {
      emojisEnabled,
      presentation,
      presentationUrl,
      activeSlide,
      background,
      portraitPresentationSlug,
      showLogin,
      stateDocId,
      showSplash,
      isControlled,
      codeSubmitError,
      isLoading,
      checkedPrivacy,
    } = this.state;
    const presentationReady = presentation || presentationUrl;
    const urlParams = new URLSearchParams(window.location.search);
    const noUI = urlParams.get('no_ui');
    return (
      <div className="presentation-view">
        {presentationReady && (
          <>
            {' '}
            <HtmParser
              showControls={false}
              onReadTotalSlides={total => {
                this.setState({ totalPages: total });
              }}
              slideIdx={activeSlide}
              presentation={presentation}
              url={presentationUrl}
              isPortraitLayout={portraitPresentationSlug.length > 0}
              theme={{
                background: background,
              }}
            />
            {emojisEnabled && emojisEnabled !== 'none' && noUI !== '1' && (
              <Box className="emoji-container">
                <div className="mr-sm sm:mb-md">
                  <Emoji emoji="1F600" />
                </div>
                <div className="mr-sm sm:mb-md">
                  <Emoji emoji="1F493" />
                </div>
                <div className="mr-sm sm:mb-md">
                  <Emoji emoji="00LFL" />
                </div>
              </Box>
            )}
          </>
        )}
        {!isControlled && presentationReady && this.renderUi()}
        {showSplash && checkedPrivacy && stateDocId && !presentationReady && (
          <Splash
            showLogin={showLogin}
            onSubmit={this.checkAuth}
            error={codeSubmitError}
          />
        )}
        {isLoading && (
          <div className="presentation-view__loader-container">
            <div className="loader">
              <Loader />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ClientPresView;
