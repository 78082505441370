import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import firebase from 'firebase';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { ColorPicker } from 'material-ui-color';
import Alert from '../Alert';
import Loader from '../Loader';
import Preview from '../Preview';
import styles from './style.module.scss';
import { db } from '../../lib/loadFirebase';

const NUM_OPTIONS = 6;
const NUM_SHARDS = 5;
const chartTypes = [
  { name: 'Vertical Bar Chart', value: 'vertical-bar' },
  { name: 'Horizontal Bar Chart', value: 'horizontal-bar' },
];
const resultDataTypes = [
  { name: 'Raw numbers', value: 'raw' },
  { name: 'Percentages', value: 'percent' },
];
const resultSortTypes = [
  { name: 'Fixed: original order', value: 'none' },
  { name: 'Dynamic: highest to lowest', value: 'desc' },
];
function EditPollPage({ selectedPoll = '', onCreateNewPoll, hideSelection }) {
  const [selected, setSelected] = useState(selectedPoll);
  const [isNew, setIsNew] = useState(false);
  const [polls, setPolls] = useState({});
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showResetAlert, setShowResetAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const query = db.collection('polls').doc();
  const formik = useFormik({
    initialValues: {
      options: [],
      question: '',
      slug: query.id,
      name: '',
      theme: {
        axis: '#ffffff',
        background: '#ffffff',
        bar: '#C9234B',
        barMargin: '0 8vw',
        borderBottomRad: '20px',
        borderTopRad: '20px',
        buttonBg: '#C9234B',
        buttonBorderRadius: '100px',
        buttonText: '#ffffff',
        chartType: chartTypes[0].value,
        resultDataType: resultDataTypes[0].value,
        resultSortType: resultSortTypes[0].value,
        text: '#0B3056',
      },
    },

    onSubmit: async values => {
      setLoading(true);
      const query = db.collection('polls').doc(values.slug);
      if (isNew) {
        await query.set({
          ...values,
          options: values.options.filter(option => !!option.value),
        });

        for (const option of values.options) {
          for (let shard = 1; shard <= NUM_SHARDS; shard++) {
            await db
              .doc(`polls/${values.slug}/${option.value}Shards/count${shard}`)
              .set({ count: 0 });
          }
        }
        if (onCreateNewPoll) {
          onCreateNewPoll({ ...values });
        }
        setPolls({ ...polls, [values.slug]: values });
      } else {
        await db.collection('polls').doc(selected).set(values);

        // Delete empty options
        for (const option of values.options) {
          if (!option.value) {
            await db
              .collection('polls')
              .doc(selected)
              .update({
                options: firebase.firestore.FieldValue.arrayRemove(option),
              });
          }
        }

        setPolls({ ...polls, [selected]: values });
      }

      setLoading(false);
      const newSelected = isNew ? values.slug : selected;
      window.parent.postMessage(
        {
          message: 'poll_set_data',
          slug: newSelected,
          question: values.question,
        },
        '*'
      );

      setSelected(newSelected);
    },
  });

  async function init() {
    const snapshots = await db.collection('polls').get();

    if (snapshots.docs.length) {
      const docs = snapshots.docs.map(doc => doc.data());

      setPolls(keyBy(docs, 'slug'));
      const params = new URLSearchParams(window.location.search);
      if (params.has('slug')) {
        setSelected(params.get('slug'));
      }
      if (selectedPoll) {
        setSelected(selectedPoll);
      }
    }
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (selected) {
      const selectedPoll = polls[selected];

      if (selectedPoll) {
        setIsNew(false);
        formik.setValues({ ...selectedPoll });
        window.parent.postMessage(
          {
            message: 'poll_set_data',
            slug: selectedPoll.slug,
            question: selectedPoll.question,
          },
          '*'
        );

        if (window.history.replaceState) {
          var newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            `?slug=${selectedPoll.slug}`;
          window.history.replaceState({ path: newurl }, '', newurl);
        }
      } else {
        setIsNew(true);
        formik.handleReset();

        window.parent.postMessage(
          {
            message: 'poll_set_data',
            slug: '',
            question: '',
          },
          '*'
        );

        if (window.history.replaceState) {
          var newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname;
          window.history.replaceState({ path: newurl }, '', newurl);
        }
      }
    }
  }, [selected, polls]);

  function toggleAlert(alert) {
    switch (alert) {
      case 'reset':
        setShowResetAlert(!showResetAlert);
        break;
      case 'delete':
        setShowDeleteAlert(!showDeleteAlert);
        break;
      default:
        break;
    }
  }

  function handleOptionChange(value, i) {
    formik.setFieldValue(`options[${i}].label`, value);
    // Set value to a, b, c, d, etc...
    formik.setFieldValue(
      `options[${i}].value`,
      !!value ? String.fromCharCode(97 + i) : ''
    );

    // If option is just created, set the isCorrect value to false
    if (get(formik, ['values', 'options', i, 'isCorrect']) === undefined) {
      formik.setFieldValue(`options[${i}].isCorrect`, false);
    }

    if (!value) {
      formik.setFieldValue(`options[${i}].isCorrect`, false);
    }
  }

  function handleIsCorrectChange({ target: { checked } }, i) {
    formik.setFieldValue(`options[${i}].isCorrect`, checked);
  }

  async function handleDelete() {
    setLoading(true);

    try {
      await db.collection('polls').doc(selected).delete();
      const { [selected]: deleted, ...remaining } = polls;

      setPolls(remaining);
      window.parent.postMessage({ message: 'poll_set_data', slug: '' }, '*');

      setSelected('');
    } catch (error) {
      // TODO: Add error handling
      console.log(error);
    } finally {
      toggleAlert('delete');
      setLoading(false);
    }
  }

  async function handleReset() {
    for (const option of formik.values.options) {
      for (let shard = 1; shard <= NUM_SHARDS; shard++) {
        await db
          .doc(
            `polls/${formik.values.slug}/${option.value}Shards/count${shard}`
          )
          .set({ count: 0 });
      }
    }

    toggleAlert('reset');
  }

  function renderColorPicker(title, prop) {
    return (
      <>
        <InputLabel shrink>{title}</InputLabel>
        <ColorPicker
          value={get(formik, `values.theme.${prop}`)}
          onChange={color =>
            formik.setFieldValue(`theme.${prop}`, `#${color.hex}`)
          }
          inputFormats={['hex', 'rgb']}
          disableTextfield
          deferred
        />
      </>
    );
  }

  function renderContent() {
    if (loading) {
      return (
        <div className={styles.loader}>
          <Loader />
        </div>
      );
    }

    if (!!selected) {
      return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={2}>
            <Grid
              item
              container
              direction="row"
              sm={12}
              md={8}
              spacing={3}
              justify="space-around"
            >
              <Grid
                item
                container
                direction="column"
                sm={12}
                md={6}
                spacing={1}
              >
                <Grid item>
                  <h2>Questions and options</h2>
                </Grid>
                <Grid item>
                  <TextField
                    id="slug"
                    label="UUID"
                    value={formik.values.slug}
                    onChange={formik.handleChange}
                    required
                    fullWidth
                    disabled={!isNew}
                  />
                  <TextField
                    id="name"
                    label="Poll Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Question"
                    id="question"
                    value={formik.values.question}
                    onChange={formik.handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                {Array.from(Array(NUM_OPTIONS)).map((_, i) => (
                  <Grid item container key={`option[${i}]`}>
                    <Grid item xs={9} lg={10}>
                      <TextField
                        id={`option[${i}]`}
                        label={`Option ${i + 1}`}
                        value={get(
                          formik,
                          ['values', 'options', i, 'label'],
                          ''
                        )}
                        onChange={e => handleOptionChange(e.target.value, i)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={get(
                              formik,
                              ['values', 'options', i, 'isCorrect'],
                              false
                            )}
                            onChange={e => handleIsCorrectChange(e, i)}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Is correct"
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                container
                direction="column"
                sm={12}
                md={6}
                spacing={1}
              >
                <Grid item>
                  <h2>Theme</h2>
                </Grid>
                {[
                  { title: 'Page Background', prop: 'background' },
                  { title: 'Question Text', prop: 'text' },
                  { title: 'Axis', prop: 'axis' },
                  { title: 'Bar', prop: 'bar' },
                ].map(({ title, prop }) => (
                  <Grid item key={prop}>
                    {renderColorPicker(title, prop)}
                  </Grid>
                ))}
                <Grid item>
                  <TextField
                    label="Bar Margin"
                    value={get(formik, 'values.theme.barMargin', '')}
                    onChange={e =>
                      formik.setFieldValue('theme.barMargin', e.target.value)
                    }
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Border Bottom Radius"
                    value={get(formik, 'values.theme.borderBottomRad', '')}
                    onChange={e =>
                      formik.setFieldValue(
                        'theme.borderBottomRad',
                        e.target.value
                      )
                    }
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Border Top Radius"
                    value={get(formik, 'values.theme.borderTopRad', '')}
                    onChange={e =>
                      formik.setFieldValue('theme.borderTopRad', e.target.value)
                    }
                    required
                    fullWidth
                  />
                </Grid>
                {[
                  { title: 'Button Background', prop: 'buttonBg' },
                  { title: 'Button Text', prop: 'buttonText' },
                ].map(({ title, prop }) => (
                  <Grid item key={prop}>
                    {renderColorPicker(title, prop)}
                  </Grid>
                ))}
                <Grid item>
                  <TextField
                    label="Button Border Radius"
                    value={get(formik, 'values.theme.buttonBorderRadius', '')}
                    onChange={e =>
                      formik.setFieldValue(
                        'theme.buttonBorderRadius',
                        e.target.value
                      )
                    }
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <InputLabel id="chart-type-label">
                    Results Graph Type
                  </InputLabel>
                  <Select
                    labelId="chart-type-label"
                    id="poll-slug-select"
                    value={get(
                      formik,
                      'values.theme.chartType',
                      chartTypes[0].value
                    )}
                    onChange={e => {
                      console.log('e', e, e.target.value);
                      formik.setFieldValue('theme.chartType', e.target.value);
                    }}
                  >
                    {chartTypes.map(chartType => (
                      <MenuItem value={chartType.value}>
                        {chartType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <InputLabel id="data-type-label">
                    Results Data Type
                  </InputLabel>
                  <Select
                    labelId="data-type-label"
                    id="poll-data-type-select"
                    value={get(
                      formik,
                      'values.theme.resultDataType',
                      resultDataTypes[0].value
                    )}
                    onChange={e => {
                      console.log('e', e, e.target.value);
                      formik.setFieldValue(
                        'theme.resultDataType',
                        e.target.value
                      );
                    }}
                  >
                    {resultDataTypes.map(type => (
                      <MenuItem value={type.value}>{type.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <InputLabel id="data-type-label">Sort Results</InputLabel>
                  <Select
                    labelId="data-type-label"
                    id="poll-data-type-select"
                    value={get(
                      formik,
                      'values.theme.resultSortType',
                      resultSortTypes[0].value
                    )}
                    onChange={e => {
                      console.log('e', e, e.target.value);
                      formik.setFieldValue(
                        'theme.resultSortType',
                        e.target.value
                      );
                    }}
                  >
                    {resultSortTypes.map(type => (
                      <MenuItem value={type.value}>{type.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="flex-end">
                <Grid item>
                  <Button variant="contained" color="primary" type="submit">
                    {isNew ? 'Create' : 'Save'}
                  </Button>
                </Grid>
                {!isNew && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => toggleAlert('delete')}
                      >
                        Delete
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => toggleAlert('reset')}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        href={`${window.location.origin}/poll/${selected}`}
                        target="_blank"
                      >
                        Show Poll
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item container direction="column" sm={12} md={4}>
              <Grid item>
                <Preview type="poll" values={formik.values} hideToggle />
              </Grid>
            </Grid>
          </Grid>
        </form>
      );
    }
  }
  return (
    <div className={styles.page}>
      <div
        className={styles.select}
        style={{ display: hideSelection ? 'none' : 'block' }}
      >
        <FormControl fullWidth>
          <InputLabel id="poll-slug-label">Polls</InputLabel>
          <Select
            labelId="poll-slug-label"
            id="poll-slug-select"
            value={selected}
            onChange={e => {
              setSelected(e.target.value);
            }}
          >
            <MenuItem value="new-poll-value">Create a new poll</MenuItem>
            {Object.keys(polls).map(value => (
              <MenuItem value={polls[value].slug} key={polls[value].slug}>
                {polls[value].name || polls[value].question}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText id="my-helper-text">
            Create a new poll or edit an existing one
          </FormHelperText>
        </FormControl>
      </div>
      {renderContent()}
      <Alert
        open={showResetAlert}
        title={'Are you sure you want to reset this poll?'}
        handleConfirm={handleReset}
        handleClose={() => toggleAlert('reset')}
        confirmText="Reset"
      >
        You can't undo this action.
      </Alert>
      <Alert
        open={showDeleteAlert}
        title={'Are you sure you want to delete this poll?'}
        handleConfirm={handleDelete}
        handleClose={() => toggleAlert('delete')}
        confirmText="Delete"
      >
        <DialogContentText>
          This item will be deleted immediately. You can't undo this action.
        </DialogContentText>
      </Alert>
    </div>
  );
}

export default EditPollPage;
