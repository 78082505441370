import React from 'react';
import HomeAsset from 'src/assets/lfl_unavailable.svg';
import styles from './style.module.scss';
import GenericButton from 'src/GenericButton';

const NotFound = () => {

  const goHome = () => {
    window.location = 'https://www.leftfieldlabs.com';
  }

  return (
    <div className={styles.page}>
      <div className={styles.sorryContainer}>
        <img
          className={styles.sorryImg}
          src={HomeAsset}
          alt="Sorry this page is unavailable"
        />
        <GenericButton
          onClick={goHome}
          label="Return to homepage"
          type="bw"
        />
      </div>
    </div>
  );

}

export default NotFound;
