import React from 'react';
import LoginButton from '../LoginButton';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import style from './style.module.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSimple: false,
    };
  }
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('simpleHeader')) {
      this.setState({ isSimple: true });
    }
  }

  render() {
    const { authStore } = this.props;
    return (
      <header
        className={`${style.header} ${
          this.props.fade || this.state.isSimple ? style.fade : ''
        }`}
      >
        <h1>Presentably</h1>
        <nav>
          {!this.state.isSimple && (
            <>
              <NavLink activeClassName={style.active} exact to="/admin">
                Presentations
              </NavLink>
              {authStore.permissions.canManageUsers && (
                <NavLink activeClassName={style.active} exact to="/admin/users">
                  Users
                </NavLink>
              )}
              <NavLink
                activeClassName={style.active}
                exact
                to="/admin/edit-poll"
              >
                Edit Poll
              </NavLink>
              <NavLink
                activeClassName={style.active}
                exact
                to="/admin/edit-trivia"
              >
                Edit Trivia
              </NavLink>
            </>
          )}
          <LoginButton authStore={authStore} />
        </nav>
      </header>
    );
  }
}

export default inject('authStore')(observer(Header));
