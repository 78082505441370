import React from 'react';
import moment from 'moment';
import { db } from 'src/lib/loadFirebase';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExportIcon from '@material-ui/icons/CloudUpload';

import { collections } from 'src/constants';

const styles = theme => ({
  dialog__paper: {
    width: '360px',
    padding: theme.spacing(2),
  },
  dialogTitle__root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogTitle__text: {
    fontSize: '1.6em',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

class HistoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
    };
  }

  componentDidMount() {}

  onEnter = () => {
    db.collection(collections.HISTORY)
      .where('presentationId', '==', this.props.presentation.id)
      .get()
      .then(querySnapshot => {
        const events = [];
        querySnapshot.forEach(event => events.push(event.data()));
        this.setState({
          events,
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  };

  onExited = () => {
    this.setState({
      events: [],
    });
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <Dialog
        id="history-dialog"
        disableBackdropClick
        disableEscapeKeyDown
        classes={{ paper: this.props.classes.dialog__paper }}
        open={this.props.open}
        onEnter={this.onEnter}
        onExited={this.onExited}
        onClose={this.onClose}
        aria-labelledby="export-dialog__title"
      >
        <DialogTitle
          id="history-dialog__title"
          disableTypography
          classes={{ root: this.props.classes.dialogTitle__root }}
        >
          <Typography
            variant="h2"
            className={this.props.classes.dialogTitle__text}
          >
            Export History
          </Typography>
          <IconButton
            aria-label="Close"
            className={this.props.classes.closeButton}
            onClick={this.onClose}
            edge="end"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List className={this.props.classes.list}>
            {this.state.events.map((event, index) => (
              <ListItem key={index} alignItems="flex-start">
                <ListItemIcon>
                  <ExportIcon fontSize="large" />
                </ListItemIcon>
                <ListItemText
                  primary={`v.${event.version}`}
                  secondary={
                    <React.Fragment>
                      <Typography variant="body1" component="p">
                        {moment(event.exportedAt.toDate()).calendar()}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {`by ${event.exportedBy.name}`}
                      </Typography>
                    </React.Fragment>
                  }
                  secondaryTypographyProps={{ component: 'div' }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(HistoryModal);
