import React, { Component } from 'react';
import { db } from '../../lib/loadFirebase';
import PollPage from '../PollPage';
import { PHASES } from '../EditPollSequencePage';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
const styles = theme => ({
  section: {
    boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.2)',
    borderRadius: '5px',
    padding: '30px 24px',
  },
  markdown: {
    '& img': {
      width: '100%',
    },
    '& h1': {
      textAlign: 'center',
    },
  },
});
class PollSequencePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePoll: null,
      polls: [],
    };
  }

  init = async () => {
    this.listeners = [];
    this.sequenceListener = db
      .collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .onSnapshot(snapshot => {
        if (snapshot.exists) {
          const data = snapshot.data();
          const activePoll = data.polls.find(poll => poll.isActive === true);
          this.setState({
            polls: data.polls,
            activePoll: activePoll,
            phase: data.phase,
            preMarkdown: data.preMarkdown,
            postMarkdown: data.postMarkdown,
          });
        } else {
          this.setState({ error: 'Poll not found! :(' });
          console.log('No such document!');
        }
      });
  };

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    if (this.sequenceListener) {
      this.sequenceListener();
    }
  }

  render() {
    const { activePoll, phase, preMarkdown, postMarkdown } = this.state;
    return (
      <div>
        {activePoll && phase === PHASES.DURING && (
          <PollPage
            pollId={activePoll.uid}
            controlled
            mode={activePoll.mode}
          ></PollPage>
        )}
        <Box>
          <ReactCSSTransitionGroup
            transitionName="fade"
            transitionEnterTimeout={600}
            transitionLeaveTimeout={600}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {(phase === PHASES.PRE || phase === PHASES.POST) && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                minHeight="100vh"
                width="100%"
                maxWidth="400px"
              >
                <ReactMarkdown className={this.props.classes.markdown}>
                  {phase === PHASES.PRE ? preMarkdown : postMarkdown}
                </ReactMarkdown>
              </Box>
            )}
          </ReactCSSTransitionGroup>
        </Box>
      </div>
    );
  }
}

export default withStyles(styles)(PollSequencePage);
