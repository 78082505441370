import styles from './_variables.scss';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: styles.mainColor,
    },
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: 0,
        fontSize: '1rem',
        textTransform: 'none',
        '@media (min-width: 600px)': {
          minWidth: 0,
        },
      },
    },
    MuiTabs: {
      flexContainer: {
        borderBottom: '2px solid #eee;',
      },
    },
  },
});

export default theme;
