import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import FlipMove from 'react-flip-move';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
export default class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true });
    }, 500);
  }

  render() {
    const { mounted } = this.state;
    const { data, title, theme, showAnswer } = this.props;
    const topValue = data[0]
      ? data.reduce((prev, cur) => {
          return prev.value >= cur.value ? prev : cur;
        }).value
      : 0;
    const totalVotes = data.reduce((prev, cur) => ({
      value: prev.value + cur.value,
    }));
    const chartHeight = 55; //vh

    const dataItems = data.map((item, idx) => {
      const percentageHeight = item.value / topValue;
      const height =
        item.value <= 0 ? '1px' : `${percentageHeight * chartHeight}vh`;
      const percentOverall = totalVotes.value
        ? (item.value / totalVotes.value) * 100
        : 0;
      return (
        <div
          key={item.label}
          className={`${styles['data-group']} ${
            item.isCorrect ? styles.c : ''
          }`}
          style={{ width: `${100 / data.length}%` }}
        >
          <div
            className={`${styles.bar} ${item.isCorrect ? styles.c : ''}`}
            style={{
              height: `${height}`,
              background: theme.bar || '#91D7AF',
              margin: theme.barMargin,
              borderTopLeftRadius: theme.borderTopRad,
              borderTopRightRadius: theme.borderTopRad,
              borderBottomRightRadius: theme.borderBottomRad,
              borderBottomLeftRadius: theme.borderBottomRad,
            }}
          >
            <div
              className={`${styles.count} ${item.value < 0 && styles.negative}`}
              style={theme ? { color: theme.text } : {}}
            >
              {theme.resultDataType === 'percent'
                ? percentOverall.toFixed(1) + '%'
                : item.value}
            </div>
          </div>
          <div
            className={styles.label}
            style={{
              ...(theme ? { color: theme.text, lineHeight: '100%' } : {}),
              ...(data.length > 5
                ? {
                    transform: 'rotate(-30deg)',
                    lineHeight: '100%',
                  }
                : {}),
            }}
          >
            {item.label}
          </div>
        </div>
      );
    });
    return (
      <>
        <ReactCSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
          className={styles['title-container']}
        >
          <div
            key={`chart-title-${title}`}
            className={`color--payments-dark h2 ${styles.title}`}
            style={theme ? { color: theme.text } : {}}
          >
            {title}
          </div>
        </ReactCSSTransitionGroup>
        <div
          className={`${styles.container} ${
            showAnswer ? styles.highlighted : ''
          } ${totalVotes.value <= 1 ? styles[`no-anim`] : ''} ${
            mounted ? styles.show : ''
          }`}
        >
          <div
            className={styles.axis}
            style={
              theme
                ? {
                    borderBottom: `2px solid ${theme.axis}`,
                    borderLeft: `2px solid ${theme.axis}`,
                  }
                : {}
            }
          />
          <FlipMove
            leaveAnimation={{
              from: {
                transform: '',
                opacity: 1,
              },
              to: {
                transform: 'translateX(20px)',
                opacity: 0,
              },
            }}
            enterAnimation={{
              from: {
                transform: 'translateX(20px)',
                opacity: 0,
              },
              to: {
                transform: 'translateX(0px)',
                opacity: 1,
              },
            }}
            typeName="ul"
            className={`${styles['chart-items']} ${
              topValue === 0 ? styles.empty : ''
            }`}
            duration={totalVotes.value <= 1 ? 0 : 300}
          >
            {dataItems}
          </FlipMove>
        </div>
      </>
    );
  }
}
Chart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  showAnswer: PropTypes.bool,
};
