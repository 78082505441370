import React, { Component } from 'react';
import './checkbox.scss';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.props.onChange(!this.props.isChecked);
  }
  render() {
    return (
      <div className="container blue">
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={this.props.isChecked}
              onChange={this.handleChange}
            />
            <span className="checkbox-material">
              <span className="check"></span>
            </span>
            {this.props.label}
          </label>
        </div>
      </div>
    );
  }
}

export default Checkbox;
