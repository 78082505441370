import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Header from './Header';
class GatedAdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.hasAuth) {
      return <Header />;
    } else {
      const Ctr = this.props.authChild;
      return (
        <div>
          <Header fade={this.props.headerFade} />
          <Ctr {...this.props} />
        </div>
      );
    }
  }
}

export default observer(GatedAdminPage);
