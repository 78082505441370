import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import gfm from 'remark-gfm';
import { render } from 'react-dom';
import { db } from '../../lib/loadFirebase';
import EditPollPage from '../EditPollPage';
import TabPanel from '../TabPanel';

export const PHASES = {
  PRE: 'pre',
  DURING: 'during',
  POST: 'post',
};

const styles = theme => ({
  section: {
    boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.2)',
    borderRadius: '5px',
    padding: '30px 24px',
  },
  markdown: {
    '& img': {
      width: '100%',
    },
    '& h1': {
      textAlign: 'center',
    },
  },
});

class EditPollSequencePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePoll: null,
      isAutomaticProgression: false,
      polls: [],
      allPolls: [],
      phase: '',
      preMarkdown: '',
      postMarkdown: '',
      preMarkdownNew: '',
      postMarkdownNew: '',
      activeTab: 1,
      sequenceExists: false,
    };
  }

  init = async () => {
    this.listeners = [];
    this.sequenceListener = db
      .collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .onSnapshot(snapshot => {
        if (snapshot.exists) {
          const data = snapshot.data();
          const activePoll = data.polls.find(poll => poll.isActive === true);

          this.setState({
            polls: data.polls,
            activePoll: activePoll,
            error: null,
            isAutomaticProgression: data.isAutomaticProgression,
            phase: data.phase,
            postMarkdown: data.postMarkdown,
            postMarkdownNew: data.postMarkdown,
            preMarkdown: data.preMarkdown,
            preMarkdownNew: data.preMarkdown,
            sequenceExists: true,
          });
        } else {
          this.setState({ error: '404' });
        }
      });
    this.getAllPolls();
  };
  initializePollSequence() {
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .set({ polls: [], phase: 'pre' });
  }
  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    if (this.sequenceListener) {
      this.sequenceListener();
    }
  }

  getAllPolls = async () => {
    const snapshots = await db.collection('polls').get();

    if (snapshots.docs.length) {
      const docs = snapshots.docs.map(doc => doc.data());

      this.setState({ allPolls: docs });
    }
  };

  addNewPoll(poll) {
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .update({
        polls: [
          ...this.state.polls,
          {
            uid: poll.slug,
            name: poll.name,
            question: poll.question,
            isActive: false,
            mode: 'auto',
          },
        ],
      });
    this.setState({ showPollModal: false, pendingPollUid: null });
  }

  initAddNewPoll() {
    this.setState({ showPollModal: true });
  }

  updateProgression(progression) {
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .update({
        isAutomaticProgression: progression,
      });
  }

  updatePhase(phase) {
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .update({
        phase: phase,
      });
  }

  submitPostMarkdown() {
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .update({
        postMarkdown: this.state.postMarkdownNew,
      });
  }

  submitPreMarkdown() {
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .update({
        preMarkdown: this.state.preMarkdownNew,
      });
  }

  resetPreMarkdown() {
    this.setState({ preMarkdownNew: this.state.preMarkdown });
  }

  resetPostMarkdown() {
    this.setState({ postMarkdownNew: this.state.postMarkdown });
  }

  togglePollVisibility(poll) {
    const polls = [...this.state.polls];
    const updatedPolls = polls.map(p => {
      const isPollActive = !poll.isActive && p.uid === poll.uid;
      return { ...p, isActive: isPollActive };
    });
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .update({
        polls: updatedPolls,
      });
    this.setState({ showPollModal: false, pendingPollUid: null });
  }
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const polls = this.reorder(
      this.state.polls,
      result.source.index,
      result.destination.index
    );

    this.setState({
      polls,
    });
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .update({
        polls: polls,
      });
  };

  updatePollMode(poll, mode) {
    const polls = [...this.state.polls];
    const updatedPolls = polls.map(p => {
      const updatedMode = p.uid === poll.uid ? mode : p.mode || 'auto';
      return { ...p, mode: updatedMode };
    });
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .update({
        polls: updatedPolls,
      });
  }

  removePoll(poll) {
    const polls = [...this.state.polls];
    const updatedPolls = polls.filter(p => {
      return p.uid !== poll.uid;
    });
    db.collection(`poll-groups`)
      .doc(this.props.match.params.pollSequenceId)
      .update({
        polls: updatedPolls,
      });
  }
  renderCard(poll, provided) {
    const { isAutomaticProgression } = this.state;
    return (
      <Card
        style={{
          width: '98%',
          margin: '20px auto',
          transition: 'transform 0.3s ease',
          boxShadow: `${
            poll.isActive && !isAutomaticProgression
              ? '1px 1px 5px 0px rgb(26 187 89 / 42%), 0px 1px 1px 0px rgb(40 176 81 / 33%), 0px 1px 4px 0px rgb(26 187 46 / 38%)'
              : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
          }`,
        }}
      >
        <CardContent>
          <Box display="flex">
            <InputLabel
              style={{
                margin: '0px 5px 0 0',
                display: 'flex',
                alignItems: 'center',
              }}
              id="poll-question-label"
            >
              UUID:
            </InputLabel>
            <Typography>{poll.uid}</Typography>
          </Box>
          <Box display="flex">
            <InputLabel
              style={{
                margin: '0px 5px 0 0',
                display: 'flex',
                alignItems: 'center',
              }}
              id="poll-question-label"
            >
              Name:
            </InputLabel>
            <Typography>{poll.name}</Typography>
          </Box>
          <Box display="flex">
            <InputLabel
              style={{
                margin: '0px 5px 0 0',
                display: 'flex',
                alignItems: 'center',
              }}
              id="poll-question-label"
            >
              Question:
            </InputLabel>
            <Typography>{poll.question}</Typography>
          </Box>
          <InputLabel
            style={{
              margin: '10px 5px 0 0',
              display: 'inline-block',
            }}
            id="poll-mode-label"
          >
            Mode:
          </InputLabel>
          <Select
            labelId="poll-mode-label"
            id="poll-mode-select"
            value={poll.mode || 'auto'}
            onChange={e => {
              this.updatePollMode(poll, e.target.value);
            }}
            style={{ width: '200px' }}
          >
            <MenuItem value="auto">Show results after vote</MenuItem>
            <MenuItem value="question">Show question</MenuItem>
            <MenuItem value="results">Show results</MenuItem>
            <MenuItem value="reveal">Show results w/ answer</MenuItem>
          </Select>
        </CardContent>
        <CardActions>
          <div {...provided.dragHandleProps}>
            <i className="material-icons">drag_indicator</i>
          </div>
          <IconButton
            aria-label="Edit"
            onClick={() => {
              this.setState({
                pendingPollUid: poll.uid,
                showPollModal: true,
              });
            }}
            edge="end"
          >
            <i className="material-icons">edit</i>
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => {
              this.removePoll(poll);
            }}
            edge="end"
          >
            <i className="material-icons">delete</i>
          </IconButton>
          {!isAutomaticProgression && (
            <IconButton
              aria-label="visibility"
              onClick={() => {
                this.togglePollVisibility(poll);
              }}
              edge="end"
              style={{ opacity: `${poll.isActive ? 1 : 0.7}` }}
            >
              <i className="material-icons">
                {poll.isActive ? 'visibility' : 'visibility_off'}
              </i>
            </IconButton>
          )}
        </CardActions>
      </Card>
    );
  }
  render() {
    const {
      activePoll,
      isAutomaticProgression,
      activeTab,
      error,
      sequenceExists,
    } = this.state;
    return (
      <Box pt={10} height={'100vh'} overflow={'scroll'}>
        <Box display="flex" justifyContent="center" width="100%">
          {error === '404' && (
            <Button onClick={() => this.initializePollSequence()}>
              Enable Poll Sequence
            </Button>
          )}
          {sequenceExists && (
            <Box width="100%" px={3} maxWidth={'600px'}>
              {/* <InputLabel id="progressionn-label">
                Poll Sequence Progression:
              </InputLabel>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Manual</Grid>
                <Grid item>
                  <Switch
                    checked={isAutomaticProgression}
                    onChange={e => {
                      this.updateProgression(e.target.checked);
                    }}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                <Grid item>Automatic</Grid>
              </Grid> */}
              <Box my={1}>
                <Typography
                  style={{ display: 'inline-block', marginRight: '10px' }}
                  id="phase-label"
                >
                  Live Phase:
                </Typography>
                <Select
                  labelId="phase-label"
                  id="poll-sequence-mode"
                  value={this.state.phase}
                  onChange={e => {
                    this.setState({ phase: e.target.value });
                    this.updatePhase(e.target.value);
                  }}
                >
                  <MenuItem value={PHASES.PRE}>Polls Not Started</MenuItem>
                  <MenuItem value={PHASES.DURING}>Polls Active</MenuItem>
                  <MenuItem value={PHASES.POST}>Polls Ended</MenuItem>
                </Select>
              </Box>
              <Box>
                <Typography>Edit Phases:</Typography>
              </Box>
              <Tabs
                value={activeTab}
                onChange={(e, newVal) => {
                  this.setState({ activeTab: newVal });
                }}
                aria-label="polls phase"
              >
                <Tab label="Polls Not Started" />
                <Tab label="Polls Active" />
                <Tab label="Polls Ended" />
              </Tabs>

              <TabPanel value={activeTab} index={0}>
                <Box width="100%" my={2} className={this.props.classes.section}>
                  <Typography>Polls not started markdown:</Typography>
                  <TextField
                    id="pre-multiline-static"
                    fullWidth
                    multiline
                    variant="outlined"
                    rows={14}
                    defaultValue={this.state.preMarkdownNew}
                    onChange={e => {
                      this.setState({ preMarkdownNew: e.target.value });
                    }}
                  />
                  <Typography style={{ margin: '40px 0 20px 0' }}>
                    Preview:
                  </Typography>
                  <ReactMarkdown
                    plugins={[gfm]}
                    className={this.props.classes.markdown}
                  >
                    {this.state.preMarkdownNew}
                  </ReactMarkdown>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      this.submitPreMarkdown();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      this.resetPreMarkdown();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Box width="100%" my={2} className={this.props.classes.section}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => {
                        this.initAddNewPoll();
                      }}
                    >
                      Add New Poll
                    </Button>
                  </Box>
                  <Box style={{ maxHeight: '720px', overflow: 'scroll' }}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            // style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {this.state.polls.map((poll, index) => (
                              <Draggable
                                key={poll.uid}
                                draggableId={poll.uid}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}

                                    // style={getItemStyle(
                                    //   snapshot.isDragging,
                                    //   provided.draggableProps.style
                                    // )}
                                  >
                                    {this.renderCard(poll, provided)}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <Box width="100%" my={2} className={this.props.classes.section}>
                  <InputLabel id="post-label">
                    Polls completed markdown:
                  </InputLabel>
                  <TextField
                    id="post-multiline-static"
                    fullWidth
                    multiline
                    rows={14}
                    defaultValue={this.state.postMarkdownNew}
                    variant="outlined"
                    onChange={e => {
                      this.setState({ postMarkdownNew: e.target.value });
                    }}
                  />
                  <Typography style={{ margin: '40px 0 20px 0' }}>
                    Preview:
                  </Typography>
                  <ReactMarkdown
                    plugins={[gfm]}
                    className={this.props.classes.markdown}
                  >
                    {this.state.postMarkdownNew}
                  </ReactMarkdown>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      this.submitPostMarkdown();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      this.resetPostMarkdown();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </TabPanel>
            </Box>
          )}
        </Box>
        <Dialog
          maxWidth={1400}
          fullWidth={true}
          open={this.state.showPollModal}
          onClose={() => {
            this.setState({ showPollModal: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={this.props.classes}
        >
          {
            <>
              <EditPollPage
                selectedPoll={
                  this.state.pendingPollUid
                    ? this.state.pendingPollUid
                    : 'new-poll-value'
                }
                onCreateNewPoll={poll => {
                  this.addNewPoll(poll);
                }}
                hideSelection={true}
              />
              <IconButton
                aria-label="Close"
                style={{ position: 'absolute', top: '20px', right: '30px' }}
                onClick={() => {
                  this.setState({ showPollModal: false, pendingPollUid: null });
                }}
                edge="end"
              >
                <i className="material-icons">close</i>
              </IconButton>
            </>
          }
        </Dialog>
      </Box>
    );
  }
}

export default withStyles(styles)(EditPollSequencePage);
