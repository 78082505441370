import React, { useState, useEffect } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { db } from '../../lib/loadFirebase';
import Question from '../../components/Question';
import '../../styles/_app.scss';

function TriviaPage({ match }) {
  const triviaId = match.params.triviaId;
  const queryParams = new URLSearchParams(window.location.search);

  const [error] = useState();
  const [data, setData] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [mode, setMode] = useState(queryParams.get('mode') || 'default');
  const showTitle = JSON.parse(queryParams.get('show_title') || true);

  function getMode() {
    switch (mode) {
      case 'question':
        return 'trivia';
      case 'reveal':
        return 'result';
      default:
        return 'trivia';
    }
  }

  async function init() {
    const snapshots = await db
      .collection('trivia')
      .where('slug', '==', triviaId)
      .get();

    if (snapshots.docs.length) {
      setData(snapshots.docs[0].data());
    }
  }

  useEffect(() => {
    init();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div></div>;
  }

  const { question, options, subtitle, theme } = data;

  return (
    <div className="App">
      <ReactCSSTransitionGroup
        transitionName="fade"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Question
          options={options}
          question={showTitle && question}
          subtitle={subtitle}
          source={''}
          theme={theme}
          mode={getMode()}
          onVoted={() =>
            mode === 'default' ? setMode('reveal') : setDisabled(true)
          }
          disabled={disabled}
        />
      </ReactCSSTransitionGroup>
    </div>
  );
}

export default TriviaPage;
