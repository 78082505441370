import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { db } from '../../lib/loadFirebase';
import { collections } from 'src/constants';
import UserRow from '../UserRow/UserRow';
import {
  Button,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
const defaultUser = {
  email: null,
  permissions: {
    create: false,
    update: false,
    delete: false,
    manageUsers: false,
  },
};
function ManageUsersView() {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState(defaultUser);
  const [searchTerm, setSearchTerm] = useState('');
  const [addUserOpen, setAddUserOpen] = useState(false);

  useEffect(() => {
    const unsubscribeFromUsers = db
      .collection(collections.USERS)
      .onSnapshot(snapshot => {
        let docs = [];
        snapshot.forEach(doc => {
          docs.push({ ...doc.data(), email: doc.id });
        });

        setUsers(docs);
      });
    return () => unsubscribeFromUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function addUser() {
    const dbUser = { ...newUser };
    delete dbUser.email;
    await db
      .collection(collections.USERS)
      .doc(newUser.email)
      .set({
        ...dbUser,
      })
      .then(() => {
        setAddUserOpen(false);
        setNewUser(defaultUser);
      });
  }

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h2>User Management Admin</h2>
        <Grid
          container
          justify="space-between"
          direction="row"
          alignItems="center"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              <TextField
                label={
                  <Grid container alignItems="center">
                    <Grid item>
                      <i className="material-icons">search</i>
                    </Grid>
                    <Grid item> email</Grid>
                  </Grid>
                }
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                variant="standard"
              />
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              endIcon={<i className="material-icons">add</i>}
              onClick={() => setAddUserOpen(true)}
            >
              Add User
            </Button>
          </Grid>
        </Grid>
        <Box mt={2} mb={1}>
          <Grid container direction="row">
            <Grid item xs={11}>
              <Grid container direction="row">
                <Grid item xs={3}>
                  Email
                </Grid>
                <Grid item xs={3}>
                  Presentation Permissions
                </Grid>
                <Grid item xs={3}>
                  User Management
                </Grid>
                <Grid item xs={3} lg={3}>
                  Presentation Access
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              Remove
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className={styles.list}>
        {users
          .filter(
            o => searchTerm.length === 0 || o.email.indexOf(searchTerm) >= 0
          )
          .map(u => {
            return <UserRow user={u} key={u.email} />;
          })}
      </div>
      <Dialog
        open={addUserOpen}
        onClose={() => {
          setNewUser(defaultUser);
          setAddUserOpen(false);
        }}
        p={4}
      >
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={newUser.email}
            onChange={e => setNewUser({ ...newUser, email: e.target.value })}
          />
          <DialogContentText>
            This will add a new user entry to the users table. Permissions can
            be set from the table view.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNewUser(defaultUser);
              setAddUserOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={addUser}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManageUsersView;
