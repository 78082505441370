import React, { Component } from 'react';
import { Flipper } from 'react-flip-toolkit';
import {
  AppBar,
  Tabs,
  Tab,
  Checkbox,
  Button,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import styles from './style.module.scss';
import PresentationCard from '../PresentationCard';

class PresentationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTabIndex: 0,
      adminTabs: [],
      isViewingArchived: false,
      searchTerm: "",
      searchTimeout: null,
      nextPage: 2,
      prevPage: null,
      currentPage: 1
    };
  }
  componentDidMount(props, state) {
    const groups = this.props.authStore.permissions.groups ?? {};
    const adminTabs = Object.keys(groups).filter(key => groups[key] === true);
    this.setState({ adminTabs });
  }
  copyClientLink = url => {
    const el = document.createElement('textarea');
    el.value = url;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  };

  onTabChange = (e, newTab) => {
    this.setState({
      currentTabIndex: newTab,
      currentPage: 1
    });
  };

  populatePagination = (items, page = 1, perPage = 10) => {
    const offset = perPage * (page - 1);
    const totalPages = Math.ceil(items.length / perPage);
    const paginatedItems = items.slice(offset, perPage * page);

    return {
      prevPage: page - 1 ? page - 1 : null,
      nextPage: (totalPages > page) ? page + 1 : null,
      currentPage: page,
      total: items.length,
      totalPages: totalPages,
      filteredPresentations: paginatedItems
    };
  };


  getFilteredPresentations = (presentations, tabName, searchTerm) => {
    const projectsPerPage = 42;

    const unpaginatedPresentations = presentations.filter(
      pres =>
        pres.group === tabName &&
        pres.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !!pres.isArchived === this.state.isViewingArchived
    );

    const paginatedResults = this.populatePagination(unpaginatedPresentations, this.state.currentPage, projectsPerPage);
    console.log(paginatedResults);
    return paginatedResults;
  };

  renderPresentationList = (filteredPresentations) => {
    const { presentations } = this.props;

    const handleClientLinkCopyClick = slug => {
      this.copyClientLink(`${window.location.origin}/p/${slug}`);
      this.setState({ copiedLinkSlug: slug });
    };

    return (
      <Flipper
        flipKey={filteredPresentations.length}
        className={styles.list}
        spring={{ stiffness: 500, dampingRatio: 1, damping: 45 }}
        staggerConfig={{
          default: {
            speed: 0.2,
          },
        }}
      >
        {filteredPresentations.map(pres => {
          return (
            <PresentationCard
              key={pres.id}
              pres={pres}
              presentations={presentations}
              isArchiveTabShowing={!!pres.isArchived}
              handleClientLinkCopyClick={handleClientLinkCopyClick}
              copiedLinkSlug={this.state.copiedLinkSlug}
              handleArchiveClick={this.props.handleArchiveClick}
              handleEditClick={this.props.handleEditClick}
              handleHistoryClick={this.props.handleHistoryClick}
              togglePresentationLock={this.props.togglePresentationLock}
              handleDeleteClick={this.props.handleDeleteClick}
              permissions={this.props.permissions}
              userEmail={this.props.userEmail}
              error={this.props.error}
              authStore={this.props.authStore}
            />
          );
        })}
      </Flipper>
    );
  };

  a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  render() {
    const { currentTabIndex, adminTabs, searchTerm } = this.state;
    const { presentations } = this.props;

    const { filteredPresentations, nextPage, prevPage } = this.getFilteredPresentations(
      presentations,
      adminTabs[currentTabIndex],
      searchTerm
    );

    const updatePagination = () => {
      this.setState({ nextPage, prevPage })
    }

    const onSearch = (e) => {
      const searchTerm = e.target.value;
      clearTimeout(this.state.searchTimeout)

      // Set timer to prevent constant re-rendering while user is typing and to prevent a react-flip-toolkit animation bug
      const newTimer = setTimeout(() => {
        this.setState({ searchTerm, currentPage: 1 })
      }, 400)

      this.setState({ searchTimeout: newTimer })
    }

    return (
      <div className={styles.presentationList}>
        <AppBar elevation={0} color="transparent" position="static">
          <Grid container spacing={3}>
            <Grid item>
              <Tabs
                indicatorColor="primary"
                value={this.state.currentTabIndex}
                onChange={this.onTabChange}
                aria-label="Presentation tabs"
              >
                {this.state.adminTabs.map((tab, i) => (
                  <Tab label={tab} key={tab} {...this.a11yProps(i)} />
                ))}
              </Tabs>
            </Grid>
            <Grid item className={styles.container}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    checked={this.state.isViewingArchived}
                    onChange={e =>
                      this.setState({ isViewingArchived: e.target.checked, currentPage: 1 })
                    }
                  />
                }
                label="View Archived"
              />
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                autoComplete="off"
                onChange={onSearch}
              />
            </Grid>
            <Grid item className={styles.container}>
              <Button
                className={styles.buttons} variant="outlined"
                disabled={!prevPage}
                onClick={() => {
                  this.setState({ currentPage: prevPage })
                  updatePagination();
                }}
              >
                Previous
              </Button>
              <Button
                className={styles.buttons}
                variant="outlined"
                disabled={!nextPage}
                onClick={() => {
                  this.setState({ currentPage: nextPage })
                  updatePagination();
                }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </AppBar>
        {this.renderPresentationList(filteredPresentations)}
      </div >
    );
  }
}

export default PresentationList;
