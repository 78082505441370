import React from 'react';
import style from './styles.module.scss';

class GenericButton extends React.Component {
  render() {
    return (
      <div
        className={`${style[this.props.type]} ${style.btn}`}
        onClick={this.props.onClick}
      >
        {this.props.label}
      </div>
    );
  }
}

export default GenericButton;
