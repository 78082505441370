import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { db } from '../../lib/loadFirebase';
import './styles.scss';
import '../../styles/_app.scss';

function Question({
  question,
  options,
  onVoted,
  theme,
  mode,
  disabled = false,
  showSelection = false,
  pollId = null,
  questionType = null,
}) {
  const [_, type, id] = get(window, 'location.pathname', []).split('/');
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState('');

  function castVote(value) {
    const uid = pollId || id;
    const collection = questionType || type;
    db.collection(`${collection}/${uid}/votes`)
      .add({ value })
      .then(() => {
        setError(null);
        if (showSelection) {
          setSelected(value);
        }

        if (onVoted) {
          onVoted(value);
        }
      })
      .catch(error => {
        console.log('error voting', error);
        setError(error.message);
      });
  }

  function renderOption({ value, label, isCorrect }) {
    if (mode === 'result') {
      return (
        <div
          key={value}
          className="option result btn"
          style={
            theme
              ? {
                  background: isCorrect ? theme.correctBg : theme.incorrectBg,
                  color: theme.buttonText,
                  borderRadius: theme.buttonBorderRadius,
                }
              : {}
          }
        >
          {label}
        </div>
      );
    }

    return (
      <div
        key={value}
        className="option btn"
        {...(!disabled && { onClick: () => castVote(value) })}
        style={
          theme
            ? {
                background:
                  selected === value ? theme.selectedBg : theme.buttonBg,
                color: theme.buttonText,
                borderRadius: theme.buttonBorderRadius,
              }
            : {}
        }
      >
        {label}
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div
      className="question"
      style={{
        ...(!question && { padding: 0 }),
        ...(theme ? { background: theme.background, color: theme.text } : {}),
      }}
    >
      {question && <h2>{question}</h2>}
      {options.map(renderOption)}
    </div>
  );
}

export default Question;
