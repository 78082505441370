import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const link = new HttpLink({
  uri: 'https://api-staging.eventably.co/query',
  headers: {
    'X-Pericles-Access-Token': '',
    'X-Pericles-Secret-Token': '',
  },
  fetchOptions: {
    mode: 'cors',
  },
});
const cache = new InMemoryCache();
const GQLClient = new ApolloClient({
  link,
  cache,
});

export default GQLClient;
