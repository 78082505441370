import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import gql from 'graphql-tag';

import './styles.scss';
import LogoColor from '../LogoColor';

const ADD_EMOJI = gql`
  mutation AddEmoji($emoji: String!) {
    emojiReaction(req: { unicode: $emoji }) {
      parameters
      timestamp
    }
  }
`;

const Emoji = ({ emoji }) => {
  const vals = {
    '00LFL': <LogoColor />,
    '1F600': <>😄</>,
    '1F493': <>❤️</>,
  };
  const [addEmoji] = useMutation(ADD_EMOJI);

  return (
    <div>
      <button
        className="emoji"
        onClick={() => addEmoji({ variables: { emoji: emoji } })}
      >
        <span>{vals[emoji]}</span>
      </button>
    </div>
  );
};

export default Emoji;
