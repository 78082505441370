import firebase from 'firebase';
var firebaseConfig = require(`../firebaseConfig.json`);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const functions = firebase.functions();
//export const analytics = firebase.analytics();
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const SERVER_TIMESTAMP = firebase.firestore.FieldValue.serverTimestamp();

export const getFirebaseToken = async () => {
  try {
    const token = await auth.currentUser.getIdToken(true);
    return token;
  } catch (error) {
    // Handle error
    console.log('error getting token', error);
  }
};
