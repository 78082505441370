import React, { useState, useRef } from 'react';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Results from '../Results';
import Question from '../Question';
import './styles.scss';

function Preview({ type, values, hideToggle }) {
  const [showResult, setShowResult] = useState(false);

  function renderTrivia() {
    return (
      <Question
        options={values.options.filter(option => !!option && !!option.value)}
        question={values.question}
        source={''}
        theme={values.theme}
        mode={showResult ? 'result' : 'trivia'}
        onVoted={() => setShowResult(true)}
      />
    );
  }

  function renderPoll() {
    // if (showResult) {
    //   return (
    //     <div className="result">
    //       <Results
    //         data={values.options.map((option, i) => ({
    //           label: option.label,
    //           value: (i + 1) * 2,
    //         }))}
    //         showAnswer
    //         theme={values.theme}
    //       />
    //     </div>

    //   );
    // }

    return (
      <Question
        options={values.options}
        question={values.question}
        source={''}
        theme={values.theme}
        mode={showResult ? 'result' : 'poll'}
        onVoted={() => setShowResult(true)}
      />
    );
  }

  return (
    <>
      <div className="preview">
        {type === 'poll' && renderPoll()}
        {type === 'trivia' && renderTrivia()}
      </div>
      {!hideToggle && (
        <Grid
          component="label"
          direction="row"
          container
          alignItems="center"
          justify="center"
          spacing={1}
        >
          <Grid item>Question</Grid>
          <Grid item>
            <Switch
              checked={showResult}
              onChange={e => setShowResult(e.target.checked)}
              name="showResult"
            />
          </Grid>
          <Grid item>Result</Grid>
        </Grid>
      )}
    </>
  );
}

export default Preview;
