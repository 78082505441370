import React, { Component } from 'react';
import Chart from '../Chart';
import HorizontalChart from '../HorizontalChart';
class Results extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { theme, showAnswer, data, question } = this.props;

    const containerStyle =
      theme.chartType && theme.chartType === 'horizontal-bar'
        ? {
            display: 'flex',
            flexDirection: 'column',
            alignItems: data[0] ? 'flex-start' : 'center',
          }
        : {};
    return (
      <div
        style={{
          background: `${theme ? theme.background : 'inherit'}`,
          minHeight: '100vh',
          width: '100%',
          ...containerStyle,
        }}
      >
        {(!theme.chartType || theme.chartType === 'vertical-bar') && (
          <Chart
            data={data}
            title={'Results'}
            showAnswer={showAnswer}
            theme={theme}
          />
        )}
        {theme.chartType === 'horizontal-bar' && (
          <HorizontalChart
            data={data}
            title={question}
            showAnswer={showAnswer}
            theme={theme}
          />
        )}
      </div>
    );
  }
}

export default Results;
