import React, { Component } from 'react';
import axios from 'axios';
import styles from './style.module.scss';
import PresentationList from '../PresentationList';
import SettingsModal from '../SettingsModal';
import HistoryModal from '../HistoryModal';
import { db, getFirebaseToken } from 'src/lib/loadFirebase';
import close from 'src/assets/close-24px.svg';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { observer } from 'mobx-react';
import { collections, endpoints, ADMIN_EMAIL } from 'src/constants';

class AdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presentations: [],
      isModalOpen: false,
      isHistoryModalOpen: false,
      currentPres: null,
      isConfirmationOpen: false,
      confirmationType: null,
    };
    document.title = 'Presentably: Admin Panel';
  }

  async componentDidMount() {
    const groups = this.props.authStore.permissions.groups ?? {};
    const groupsList = Object.keys(groups).filter(key => groups[key] === true);
    // const snapshots = await db.collection(collections.PRESENTATIONS).get();
    // console.log(snapshots);
    // if (snapshots.docs.length) {
    //   snapshots.docs.forEach(doc => {
    //     db.collection(collections.PRESENTATIONS)
    //       .doc(doc.id)
    //       .set({ slidesApiSlides: null }, { merge: true });
    //   });
    // }
    this.unsubscribeFromPres = db
      .collection(collections.PRESENTATIONS)
      .orderBy('modifiedAt', 'desc')
      .where('group', 'in', groupsList)
      .onSnapshot(snapshot => {
        let presentations = [...this.state.presentations];
        // watches for any presentaition data changes & updates local presentation array
        snapshot.docChanges().forEach(change => {
          const presIdx = presentations.findIndex(p => p.id === change.doc.id);
          if (presIdx === -1) {
            // initial render (all presentations)
            presentations.push({ ...change.doc.data() });
          } else {
            // presentation was removed from the list
            if (change.type === 'removed') {
              presentations.splice(presIdx, 1);
            } else {
              // real-time changes to specific nested data
              presentations[presIdx] = change.doc.data();
            }
          }
        });
        this.setState({ presentations });
      });
    // analytics.setCurrentScreen(window.location.pathname);
  }

  componentWillUnmount() {
    this.unsubscribeFromPres();
  }

  onFinishUpload = id => {
    this.setState({
      isModalOpen: false,
      currentPres: null,
      refetchId: id || this.state.currentPres.id,
    });
  };

  onSettingsUpdate = () => {
    this.setState({ isModalOpen: false, currentPres: null });
  };

  handleEditClick = pres => {
    this.setState({ currentPres: pres, isModalOpen: true });
  };

  handleHistoryClick = pres => {
    this.setState({ currentPres: pres, isHistoryModalOpen: true });
  };

  handleDeleteClick = async pres => {
    try {
      const token = await getFirebaseToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await axios.post(
        endpoints.DELETE_PRESENTATION,
        { presentationId: pres.id },
        config
      );
      this.setState({ currentPres: null });
    } catch (err) {
      console.log(err);
      this.props.authStore.setError(
        `Your request resulted in a server error. Please try again and contact ${ADMIN_EMAIL} if the problem persists.`
      );
    }
  };

  handleArchiveClick = async (presentationId, isArchiveTabShowing) => {
    try {
      const presentRef = await db.collection(collections.PRESENTATIONS);
      await presentRef
        .doc(presentationId)
        // only archive if isArchiveTabShowing is false
        .set({ isArchived: !isArchiveTabShowing }, { merge: true });
    } catch (err) {
      console.error(err);
    }
  };

  togglePresentationLock = async pres => {
    const currentPres = pres || this.state.currentPres;
    try {
      const date = new Date();
      const presentRef = await db.collection(collections.PRESENTATIONS);
      await presentRef.doc(currentPres.id).update({
        isLocked: !currentPres.isLocked,
        lockedAt: currentPres.isLocked ? null : date.toUTCString(),
        owner: {
          email: currentPres.isLocked
            ? null
            : this.props.authStore.authUser.email,
          displayName: currentPres.isLocked
            ? null
            : this.props.authStore.authUser.displayName,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  handledRefetch = () => {
    this.setState({ refetchId: null });
  };

  render() {
    return (
      <div className={`page ${styles.adminPanel}`}>
        <div className={styles.panelInner}>
          <div className={styles.panelContainer}>
            <div className={styles.panel + ' ' + styles.flexFill}>
              <div className={styles.header}></div>
              <PresentationList
                handleEditClick={this.handleEditClick}
                handleHistoryClick={this.handleHistoryClick}
                handleDeleteClick={this.handleDeleteClick}
                handleArchiveClick={this.handleArchiveClick}
                togglePresentationLock={this.togglePresentationLock}
                handledRefetch={this.handledRefetch}
                presentations={this.state.presentations}
                refetchId={this.state.refetchId}
                error={this.props.authStore.error}
                permissions={this.props.authStore.permissions}
                userEmail={this.props.authStore.authUser.email}
                isConfirmationOpen={this.state.isConfirmationOpen}
                confirmationType={this.state.confirmationType}
                authStore={this.props.authStore}
              />
            </div>
          </div>
        </div>
        <ReactCSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {this.state.isModalOpen && (
            <div className={styles.modal}>
              <div className={`${styles.modalInner} scale-in`}>
                <h2>Edit Presentation</h2>
                <SettingsModal
                  presentation={this.state.currentPres}
                  onFinishUpload={this.onFinishUpload}
                  onSettingsUpdate={this.onSettingsUpdate}
                />
                <div
                  className={`${styles.close} btn`}
                  onClick={() => {
                    this.setState({ isModalOpen: false });
                  }}
                >
                  <img src={close} alt="close" />
                </div>
              </div>
            </div>
          )}
        </ReactCSSTransitionGroup>
        <HistoryModal
          open={this.state.isHistoryModalOpen}
          onClose={() => this.setState({ isHistoryModalOpen: false })}
          presentation={this.state.currentPres}
        />
      </div>
    );
  }
}

export default observer(AdminPanel);
