import { decorate, observable, action } from 'mobx';
import axios from 'axios';
import { auth, db } from '../lib/loadFirebase';
import { getFirebaseToken } from '../lib/loadFirebase';
import firebase from 'firebase/app';
import { collections, endpoints } from '../constants';

class AuthStore {
  authUser = null;
  error = null;
  isLoading = true;
  permissions = {
    canDelete: false,
    canUpdate: false,
    canCreate: false,
    canExport: false,
    canManageUsers: false,
    groups: {
      ROW: false,
      Blue: false,
      Google: false,
      Leadership: false,
      Violet: false,
      "CP-Google": false,
      "CP-ROW": false,
    },
  };

  setAuthUser(user) {
    this.authUser = user;
    this.error = null;
  }

  setError(error) {
    this.error = error;
  }

  checkLogin = () => {
    return new Promise((resolve, reject) => {
      this.isLoading = true;
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          this.isLoading = false;
          this.setAuthUser(user);
          resolve(user.uid);
        } else {
          this.isLoading = false;
          reject(Error('no user'));
        }
      });
    });
  };

  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        this.authUser = null;
      })
      .catch(error => {
        // An error happened
        console.log('logout err', error);
      });
  }

  showGoogleLogin() {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account',
        });
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            console.log('sucess login', user);
            this.setAuthUser(user);
            // ...
          })
          .catch(error => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`${errorCode}: ${errorMessage}`);
            // // The email of the user's account used.
            // var email = error.email;
            // // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            // ...
          });
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`${errorCode}: ${errorMessage}`);
      });
  }

  login({ email, password }) {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(cred => {
        this.setAuthUser(cred.user);
        console.log('cred', cred);
      })
      .catch(err => {
        this.setError(err);
        console.log('err', err);
      });
  }

  // Get user permissions for selectively hiding buttons where necessary and enabling user view
  async getUserPermissions() {
    try {
      const user = await db.collection(collections.USERS).doc(this.authUser.email).get();
      const { permissions } = user.data() || {}; 
      this.permissions = {
        canDelete: permissions?.delete || false,
        canUpdate: permissions?.update || false,
        canCreate: permissions?.create || false,
        canExport: (permissions?.update && permissions?.create) || false,
        canManageUsers: permissions?.manageUsers || false,
        groups: permissions?.groups || false,
      };
    } catch (err) {
      console.error(err);
    }
  }
}

decorate(AuthStore, {
  authUser: observable,
  error: observable,
  setAuthUser: action,
  setError: action,
  isLoading: observable,
  permissions: observable,
});

const authStore = new AuthStore();

export default authStore;
