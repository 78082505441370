import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './presentation-view.scss';
import { storage, db } from '../lib/loadFirebase';
import { NavLink } from 'react-router-dom';
import HtmParser from '../HtmParser';
import { collections, env } from '../constants';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Box } from '@material-ui/core';
class AdminPresView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presentation: null,
      activeSlide: 0,
      stateDocId: null,
      totalPages: 0,
      isFullScreen: false,
      showSpeakerNotes: false,
      showNextPreview: false,
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSlideChange = this.handleSlideChange.bind(this);
    this.updateFullscreenState = this.updateFullscreenState.bind(this);
    document.body.addEventListener(
      'fullscreenchange',
      this.updateFullscreenState
    );
    document.title = 'Presentably: Presenter';
  }
  updateFullscreenState() {
    const isFullScreen = window.innerHeight === window.screen.height;
    this.setState({ isFullScreen: isFullScreen });
  }
  handleKeyPress(evt) {
    const { presentation } = this.state;
    const { authStore } = this.props;

    const canControl =
      !presentation.isLocked ||
      authStore.authUser.email === presentation.owner.email;

    // disable controls if user is not the owner and presentation is locked
    if (!canControl) return;

    const { key } = evt;
    if (key === 'ArrowRight' || key === 'ArrowDown') {
      this.goNext();
    } else if (key === 'ArrowLeft' || key === 'ArrowUp') {
      this.goPrevious();
    } else if (key === 'Escape') {
      this.props.history.push('/admin');
    }
  }

  handleSlideChange(evt) {
    db.doc(`states/${this.state.stateDocId}`).update({
      activeSlide: evt.target.value,
    });
  }

  goNext() {
    const { totalPages, activeSlide, stateDocId } = this.state;
    if (totalPages > 0 && activeSlide < totalPages - 1) {
      db.doc(`states/${stateDocId}`).update({
        activeSlide: this.state.activeSlide + 1,
      });
    }
  }

  resetSlides() {
    db.doc(`states/${this.state.stateDocId}`).update({
      activeSlide: 0,
    });
  }

  goPrevious() {
    const { activeSlide, stateDocId, totalPages } = this.state;
    if (totalPages > 0 && activeSlide >= 1 && this.state.stateDocId) {
      db.doc(`states/${stateDocId}`).update({
        activeSlide: activeSlide - 1,
      });
    }
  }

  togglePresentationMode() {
    const { activeSlide, stateDocId } = this.state;
    if (activeSlide >= 1 && stateDocId) {
      db.doc(`states/${stateDocId}`).update({
        activeSlide: activeSlide - 1,
      });
    }
  }

  triggerFullScreen() {
    if (this.state.isFullScreen) {
      this.closeFullscreen();
    } else {
      this.goFullScreen();
    }
  }

  goFullScreen() {
    const elm = document.body;
    if (elm.requestFullscreen) {
      elm.requestFullscreen();
    } else if (elm.mozRequestFullScreen) {
      /* Firefox */
      elm.mozRequestFullScreen();
    } else if (elm.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elm.webkitRequestFullscreen();
    } else if (elm.msRequestFullscreen) {
      /* IE/Edge */
      elm.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  renderOptions() {
    let options = [];
    for (var i = 0; i < this.state.totalPages; i++) {
      options.push(<MenuItem value={i}>Slide {i + 1}</MenuItem>);
    }
    return options;
  }

  renderUi() {
    const { presentation } = this.state;
    if (presentation) {
      const { authStore } = this.props;
      const isOwner =
        presentation.isLocked &&
        authStore.authUser.email === presentation.owner.email;

      // disable controls if user is not the owner and presentation is locked
      if (presentation.isLocked && !isOwner) return;

      const optionItems = this.renderOptions();
      return (
        <div className="ui-container">
          <div className="btn" onClick={() => this.goPrevious()}>
            <KeyboardArrowLeftIcon />
          </div>
          <FormControl className={'select'}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.activeSlide}
              onChange={this.handleSlideChange}
              style={{ paddingRight: '5px' }}
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  style={{ color: 'white' }}
                />
              )}
            >
              {optionItems}
            </Select>
          </FormControl>
          <div className="btn" onClick={() => this.goNext()}>
            <KeyboardArrowRightIcon />
          </div>
          <div
            className="btn"
            onClick={() =>
              this.setState({ showSpeakerNotes: !this.state.showSpeakerNotes })
            }
          >
            {this.state.showSpeakerNotes ? (
              <SpeakerNotesIcon />
            ) : (
              <SpeakerNotesOffIcon />
            )}
          </div>
          <div
            className="btn"
            onClick={() =>
              this.setState({ showNextPreview: !this.state.showNextPreview })
            }
          >
            {this.state.showNextPreview ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon />
            )}
          </div>
          <div className="btn" onClick={() => this.triggerFullScreen()}>
            {window.innerHeight === window.screen.height ? (
              <FullscreenExitIcon />
            ) : (
              <FullscreenIcon />
            )}
          </div>
          <div className="btn exit">
            <NavLink exact to="/admin">
              Exit
            </NavLink>
          </div>
        </div>
      );
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeFromActiveSlide) {
      this.unsubscribeFromActiveSlide();
    }
    window.removeEventListener('keyup', this.handleKeyPress);
  }

  componentDidMount() {
    //  analytics.setCurrentScreen(window.location.pathname);
    window.addEventListener('keyup', this.handleKeyPress);
    //get presentation based on slug
    this.unsubscribeFromActiveSlide = db
      .collection(collections.STATES)
      .limit(1)
      .where('slug', '==', this.props.match.params.slug)
      .onSnapshot(stateSnap => {
        stateSnap.forEach(stateSnapItem => {
          let data = stateSnapItem.data();
          if (data.activeSlide >= 0) {
            this.setState({
              activeSlide: data.activeSlide,
              stateDocId: stateSnapItem.id,
            });
          }
        });
      });
    db.collection(collections.STATES)
      .limit(1)
      .where('slug', '==', this.props.match.params.slug)
      .get()
      .then(stateSnap => {
        stateSnap.forEach(stateSnapItem => {
          let data = stateSnapItem.data();
          const id = data.id;
          db.collection(collections.PRESENTATIONS)
            .doc(id)
            .get()
            .then(doc => {
              let pres = doc.data();
              this.setState({ presentation: pres });
              if (pres && pres.exportUrl) {
                const isOwner =
                  pres.owner &&
                  this.props.authStore.authUser.email === pres.owner.email;

                /* Redirect to admin page and show notification if this presentation
              is locked and this user is not the presentation's owner */
                if (pres.isLocked && !isOwner) {
                  this.props.openNotification(pres);
                  this.props.history.push('/admin');
                }
                var gsReference = storage.refFromURL(
                  `${env.PRESENTATIONS_BUCKET}/${pres.id}/index.htm`
                );
                gsReference.getDownloadURL().then(url => {
                  this.setState({ presUrl: url });
                });
                //Reset
                db.doc(`states/${doc.id}`).update({
                  activeSlide: 0,
                }); //TODO: only reset based on a `shouldReset` settings variable
              }
            });
        });
      });
  }
  setCurrentSpeakerNotes(id) {
    const { speakerNotes } = this.state.presentation;
    console.log('speakernotes', speakerNotes, id);
    const matchingSlide = speakerNotes
      ? speakerNotes.find(item => item.slideId === id)
      : { speakerText: '' };
    this.setState({ speakerNotes: matchingSlide.speakerText });
  }
  render() {
    console.log('this.state', this.state);
    const {
      presUrl,
      activeSlide,
      presentation,
      showNextPreview,
      showSpeakerNotes,
      speakerNotes,
    } = this.state;
    return (
      <div
        className="presentation-view"
        style={{
          background: (presentation && presentation.background) || '#000000',
        }}
      >
        {presUrl && (
          <Box>
            <HtmParser
              showControls={false}
              onReadTotalSlides={total => {
                this.setState({ totalPages: total });
              }}
              onReadCurrentSlideId={id => this.setCurrentSpeakerNotes(id)}
              slideIdx={activeSlide}
              url={presUrl}
              theme={{
                background:
                  (presentation && presentation.background) || '#000000',
              }}
              // slidesApiSlides={presentation.slidesApiSlides}
              showNextPreview={showNextPreview}
              showSpeakerNotes={showSpeakerNotes}
              speakerNotes={speakerNotes}
            />
          </Box>
        )}
        {!this.props.hideControls && this.renderUi()}
      </div>
    );
  }
}

export default AdminPresView;
