/* Environment-specific parameters go here */

const environ = require('./environment');

var projectId;

if (environ.environment === 'dev') {
  projectId = 'fir-chrome-extension-test';
} else if (environ.environment === 'foleylardner') {
  projectId = 'foley-lardner-2023';
} else if (environ.environment === 'churchdwight') {
  projectId = 'church-and-dwight';
} else {
  // assume 'prod'
  projectId = 'lfl-presentations';
}

var cloudFunctionsHostname = `us-central1-${projectId}.cloudfunctions.net`;
var cloudBucketName = `${projectId}.appspot.com`;

export const env = {
  PROJECT_ID: projectId,
  PRESENTATIONS_BUCKET: `gs://${cloudBucketName}/presentations`,
};

export const endpoints = {
  CHECK_AUTH: `https://api-dot-${projectId}.appspot.com/files/accessFile`,
  DELETE_PRESENTATION: `https://${cloudFunctionsHostname}/presentations-delete`,
  GET_USER_INFO: `https://${cloudFunctionsHostname}/users-getUserInfo`,
  GET_SLIDES_API_SLIDES: `https://${cloudFunctionsHostname}/authCheckGetSlidesMeta`,
};

export const collections = {
  PRESENTATIONS: 'presentations',
  STATES: 'states',
  HISTORY: 'history',
  USERS: 'users',
};

export const ADMIN_EMAIL = 'presentably@leftfieldlabs.com';

export const adminTabNames = {
  BLUE: 'Blue',
  GOOGLE: 'Google',
  LEADERSHIP: 'Leadership',
  VIOLET: 'Violet',
  ROW: 'ROW',
  GOOGLE_PLAYBOOK: "CP-Google",
  ROW_PLAYBOOK: "CP-ROW",
};

export const confTypes = {
  REMOVE: 'REMOVE',
  LOCKED: 'LOCKED',
  UNLOCK: 'UNLOCK',
  SETTINGS: 'SETTINGS',
  ARCHIVE: 'ARCHIVE',
};
