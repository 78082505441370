import React, { useEffect, useState } from 'react';

import { db } from '../../lib/loadFirebase';
import { collections } from 'src/constants';
import {
  Checkbox,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
} from '@material-ui/core';
import styles from './style.module.scss';
import Alert from '../Alert';
function UserRow(props) {
  const [user, setUser] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  useEffect(() => {
    setUser(props.user);
  }, [props.user]);
  async function updatePermission(user, field, value) {
    const newPerms = { ...user.permissions, [field]: value };
    await db
      .doc(`${collections.USERS}/${user.email}`)
      .update({ permissions: newPerms })
      .then(() => {
        setUser({ permissions: newPerms, email: user.email });
      });
  }

  async function updateGroupPermission(user, field, value) {
    const groups = user.permissions?.groups ?? {};
    groups[field] = value;
    const newPerms = { ...user.permissions, groups };
    await db
      .doc(`${collections.USERS}/${user.email}`)
      .update({ permissions: newPerms })
      .then(() => {
        setUser({ permissions: newPerms, email: user.email });
      });
  }

  async function removeUser(user) {
    await db
      .doc(`${collections.USERS}/${user.email}`)
      .delete()
      .then(() => {
        setUser(null);
      });
  }
  if (!user) return null;
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={11}>
          <Grid container direction="row">
            <Grid item xs={3}>
              <div className={styles.email}>{user.email}</div>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user.permissions?.create}
                    onChange={e => {
                      updatePermission(user, e.target.name, e.target.checked);
                    }}
                    name="create"
                    color="primary"
                  />
                }
                label="Create"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user.permissions?.update}
                    onChange={e => {
                      updatePermission(user, e.target.name, e.target.checked);
                    }}
                    name="update"
                    color="primary"
                  />
                }
                label="Update"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user.permissions?.delete}
                    onChange={e => {
                      updatePermission(user, e.target.name, e.target.checked);
                    }}
                    name="delete"
                    color="primary"
                  />
                }
                label="Delete"
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user.permissions?.manageUsers}
                    onChange={e => {
                      updatePermission(user, e.target.name, e.target.checked);
                    }}
                    name="manageUsers"
                    color="primary"
                  />
                }
                label="Create/Delete/Update"
              />
            </Grid>
            <Grid item xs={3} lg={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.permissions?.groups?.Blue}
                    onChange={e => {
                      updateGroupPermission(
                        user,
                        e.target.name,
                        e.target.checked
                      );
                    }}
                    name="Blue"
                    color="primary"
                  />
                }
                label="Blue"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.permissions?.groups?.["CP-Google"]}
                    onChange={e => {
                      updateGroupPermission(
                        user,
                        e.target.name,
                        e.target.checked
                      );
                    }}
                    name="CP-Google"
                    color="primary"
                  />
                }
                label="CP-Google"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.permissions?.groups?.["CP-ROW"]}
                    onChange={e => {
                      updateGroupPermission(
                        user,
                        e.target.name,
                        e.target.checked
                      );
                    }}
                    name="CP-ROW"
                    color="primary"
                  />
                }
                label="CP-ROW"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.permissions?.groups?.Google}
                    onChange={e => {
                      updateGroupPermission(
                        user,
                        e.target.name,
                        e.target.checked
                      );
                    }}
                    name="Google"
                    color="primary"
                  />
                }
                label="Google"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.permissions?.groups?.Leadership}
                    onChange={e => {
                      updateGroupPermission(
                        user,
                        e.target.name,
                        e.target.checked
                      );
                    }}
                    name="Leadership"
                    color="primary"
                  />
                }
                label="Leadership"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.permissions?.groups?.ROW}
                    onChange={e => {
                      updateGroupPermission(
                        user,
                        e.target.name,
                        e.target.checked
                      );
                    }}
                    name="ROW"
                    color="primary"
                  />
                }
                label="ROW"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.permissions?.groups?.Violet}
                    onChange={e => {
                      updateGroupPermission(
                        user,
                        e.target.name,
                        e.target.checked
                      );
                    }}
                    name="Violet"
                    color="primary"
                  />
                }
                label="Violet"
              />
             </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => setShowDeleteAlert(true)}>
            <i className="material-icons">delete</i>
          </IconButton>
        </Grid>
      </Grid>
      <Alert
        open={showDeleteAlert}
        title={'Remove User'}
        handleConfirm={() => removeUser(user)}
        handleClose={() => setShowDeleteAlert(false)}
        confirmText="Remove"
      >
        <DialogContentText>
          {`Would you like to remove the user account and permissions for
           ${user.email}?`}
        </DialogContentText>
      </Alert>
    </>
  );
}

export default UserRow;
