import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Header from '../Header/index.js';
import Emoji from '../Emojis/index.js';
import { Box } from '@material-ui/core';
class DebugPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const buttons = (
      <>
        <div className="mr-sm sm:mb-md">
          <Emoji emoji="1F600" />
        </div>
        <div className="mr-sm sm:mb-md">
          <Emoji emoji="1F493" />
        </div>
        <div className="mr-sm sm:mb-md">
          <Emoji emoji="00LFL" />
        </div>
      </>
    );
    return (
      <div>
        <Header fade={this.props.headerFade} />
        <Box mt={10}>Debug Page</Box>
        {buttons}
      </div>
    );
  }
}

export default observer(DebugPage);
